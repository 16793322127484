import { Row } from "@iqmetrix/antd";
import React from "react";
import styled from "styled-components";
import { Media, MediaText } from ".";
import { Color, MediaType, TextAlignment } from "../../models";
import * as tokens from "@iqmetrix/style-tokens";

const FullWidthHeight = styled(Row)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const FullImagePreviewTextOverLay = styled.div<{
  textAlign: TextAlignment;
}>`
  position: absolute;
  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    position: relative;
  }
  height: 100%;
  min-width: ${(props) => (props.textAlign === TextAlignment.Center ? "100%" : "50%")};
  top: 0;
  display: flex;
  flex-direction: row;
  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    flex-direction: column;
  }
  align-items: center;
  text-align: ${(props) => (props.textAlign === TextAlignment.Center ? "center" : "flex-start")};
  justify-content: ${(props) => (props.textAlign === TextAlignment.Right ? "flex-end" : "flex-start")};
  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    justify-content: flex-start;
  }
`;

const TextArea = styled.div<{
  textAlign: TextAlignment;
  showOverlay: boolean;
  overlayColor: string;
}>`
  width: ${(props) => (props.textAlign === TextAlignment.Center ? "100%" : "50%")};
  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    width: 100%;
  }
  background-color: ${(props) => (props.showOverlay ? props.overlayColor : "")};
  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    background-color: white;
  }
  max-height: 100%;
  overflow: overlay;
`;

type IFullWidthMediaWithTextProps = {
  title: string;
  body: string;
  media: {
    url: string;
    type: MediaType;
    altText: string;
  };
  textAlignment?: TextAlignment;
  textColor?: Color;
  showOverlay?: boolean;
  overlayColor?: Color;
  overlayOpacity?: number;
};

export const FullWidthMediaWithText: React.FC<IFullWidthMediaWithTextProps> = ({
  title,
  body,
  media = {
    url: "",
    type: MediaType.Image,
    altText: "",
  },
  textAlignment = TextAlignment.Center,
  textColor = { red: 0, green: 0, blue: 0 },
  showOverlay = true,
  overlayColor = { red: 0, green: 0, blue: 0 },
  overlayOpacity = 0,
}) => {
  var overlayColorAsRGBA = `rgba(
    ${overlayColor.red},
    ${overlayColor.green},
    ${overlayColor.blue},
    ${(overlayOpacity ?? 0) / 100})`;

  var textColorAsRGBA = `rgba(
    ${textColor.red},
    ${textColor.green},
    ${textColor.blue})`;

  return (
    <FullWidthHeight>
      <Media {...media} />
      <FullImagePreviewTextOverLay textAlign={textAlignment}>
        <TextArea textAlign={textAlignment} showOverlay={showOverlay} overlayColor={overlayColorAsRGBA}>
          <MediaText title={title} body={body} color={textColorAsRGBA} />
        </TextArea>
      </FullImagePreviewTextOverLay>
    </FullWidthHeight>
  );
};
