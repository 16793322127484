import React from "react";
import { Typography } from "@iqmetrix/antd";
import styled from "styled-components";
import * as tokens from "@iqmetrix/style-tokens";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px ${tokens.PaddingBaseLarge};
  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    padding: 0px;
  }
  overflow: overlay;
`;

const StyledText = styled(Typography.Text)`
  padding-top: ${tokens.PaddingBaseSmall};
  color: ${(props: { color: string }) => props.color};
  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    color: black;
  }
`;

const StyledBody = styled(StyledText)`
  padding-bottom: ${tokens.PaddingBaseSmall};
`;

type IMediaCardProps = {
  title: string;
  body: string;
  color?: string;
};

export const MediaText: React.FC<IMediaCardProps> = ({ title, body, color }) => (
  <StyledDiv>
    <StyledText color={color ?? ""} strong>
      {title}
    </StyledText>
    <StyledBody color={color ?? ""}>{body}</StyledBody>
  </StyledDiv>
);
