import React from "react";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { PageLayout } from "./PageLayout";

interface TrackedPageProps extends RouteComponentProps {
  component: React.ComponentType<any>;
}

export const TrackedPage: React.FC<TrackedPageProps> = ({ component, ...otherProps }) => {
  const Component = component;
  return (
    <PageLayout>
      <Component {...otherProps} />
    </PageLayout>
  );
};
