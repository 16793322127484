import { Breadcrumb, RightOutlined } from "@iqmetrix/antd";
import React, { useContext } from "react";
import { useIntl } from "../../hooks";
import { ReservationContext } from "../../contexts";

export const ReservationBreadcrumb: React.FC = () => {
  const intl = useIntl();
  const { reservationState } = useContext(ReservationContext);
  const { details: product } = { ...reservationState.reservation?.product };

  return (
    <Breadcrumb
      separator={<RightOutlined />}
      routes={[
        {
          path: "",
          breadcrumbName: product?.name || "...",
        },
        {
          path: "reserve",
          breadcrumbName: intl.formatMessage("Reservation.Breadcrumb.Reserve"),
        },
      ]}
      itemRender={(route: any, params: any, routes: any, paths: any) => {
        const last = routes.indexOf(route) === routes.length - 1;

        return last ? (
          <span>{route.breadcrumbName}</span>
        ) : (
          <a href={route.path} target="_top">
            {route.breadcrumbName}
          </a>
        );
      }}
    />
  );
};
