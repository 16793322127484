import { Space, Typography } from "@iqmetrix/antd";
import React from "react";
import DOMPurify from "dompurify";
import styled from "styled-components";
import * as tokens from "@iqmetrix/style-tokens";
import { useIntl } from "../../hooks";
import { ProductOptions, ProductPrice, ProductReservation, StockBadge, ChangeLocationButton } from ".";
import { ImageCarousel, LocationDetails } from "../../components";
import { useLocationDetails } from "../location";

const { Text, Title } = Typography;
const ProductHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: ${tokens.MarginBaseBase};
`;

const ProductHeaderTitle = styled(Title)`
  &.ant-typography {
    margin-bottom: 0px;
  }

  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    && {
      font-size: ${tokens.FontSizeBaseDisplaySmall};
      font-weight: ${tokens.FontWeightBaseHeading};
      line-height: ${tokens.LineHeightBaseDisplaySmall};
      margin-bottom: 0px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  gap: ${tokens.SpacingBaseXlarge};

  @media (max-width: ${tokens.WidthScreenMaxMedium}) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

const ProductDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Carousel = styled(ImageCarousel)`
  margin: 0;
`;

interface ProductDetailsProps {
  name: string;
  description: string;
  price?: number;
  options: Record<string, string>;
  assets: string[];
  isWirelessDevice: boolean;
  useStartingAtPricing: boolean;
  quantityOnHand?: number;
  ropisEnabled: boolean;
  onReserve: (quantity: number) => void;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  name,
  description,
  price,
  options,
  assets,
  isWirelessDevice,
  useStartingAtPricing,
  quantityOnHand,
  ropisEnabled,
  onReserve,
}) => {
  const intl = useIntl();
  const hasProductOptions = Object.keys(options).length > 0;
  const showProductReservation = ropisEnabled && quantityOnHand != null && price != null;
  const { locationDetails } = useLocationDetails();

  return (
    <>
      <div>
        <ProductHeader>
          <ProductHeaderTitle id="title">{name}</ProductHeaderTitle>
        </ProductHeader>
      </div>
      <Container>
        <Carousel assets={assets} altText={name} />
        <ProductDetailsContainer>
          <Space direction="vertical" size="large">
            {description && (
              <Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(description),
                  }}
                />
              </Text>
            )}
            {hasProductOptions && <ProductOptions options={options} />}
            {price != null && (
              <ProductPrice
                price={price}
                isWirelessDevice={isWirelessDevice}
                useStartingAtPricing={useStartingAtPricing}
              />
            )}
            <div>
              {showProductReservation ? (
                <ProductReservation quantityOnHand={quantityOnHand} onReserve={onReserve} />
              ) : (
                <Title level={4}>{intl.formatMessage("Product.CallToReserve")}</Title>
              )}
              <Space align="start" size="middle">
                <StockBadge visible={quantityOnHand != null} inStock={!!quantityOnHand} />
                <Space direction="vertical">
                  <LocationDetails location={locationDetails} />
                  <ChangeLocationButton />
                </Space>
              </Space>
            </div>
          </Space>
        </ProductDetailsContainer>
      </Container>
    </>
  );
};
