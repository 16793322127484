import React from "react";
import { Router } from "@gatsbyjs/reach-router";
import { ProductPage, PointyEntryPage, NotFoundPage, ProductReservationPage, TrackedPage } from "./pages";
import { Paths } from "./constants/paths";
import { Location } from "./pages/location";
import { Product } from "./pages/product";

export const App: React.FC = () => {
  return (
    <>
      <Router>
        <TrackedPage component={PointyEntryPage} path={Paths.PointyEntry} />
        <TrackedPage component={PointyEntryPage} path={Paths.PointyEntryDeprecated} />
        <Location path="/location/:companyId/:locationId">
          <Product path="/product/:catalogItemId">
            <TrackedPage component={ProductPage} path="/" />
            <TrackedPage component={ProductReservationPage} path="/reserve" />
            <NotFoundPage default />
          </Product>
          <NotFoundPage default />
        </Location>
        <NotFoundPage default />
      </Router>
    </>
  );
};
