const messages = {
  appName: "Omni.Storefront",

  "Footer.PoweredByIqmetrix": "Powered by iQmetrix",
  "Footer.TermsOfUse": "Terms of Use",

  "Product.Tabs.Specifications": "Specifications",
  "Product.Tabs.Features": "Features",
  "Product.Details.Price": "Price",
  "Product.Details.Price.StartingAt": "Starting at",
  "Product.Location.ChangeLocation": "Change location",
  "Product.ReserveQuantity": "Quantity",
  "Product.ReserveButton": "Continue to reservation",
  "Product.InStock": "In stock",
  "Product.SoldOut": "Sold out",
  "Product.CallForAvailability": "Please call this location for stock availability.",
  "Product.CallToReserve": "Please call this location to reserve.",
  "Product.PriceDisclaimer.Device":
    "* An in-store specialist will assist you with payments, applicable trade-ins, in-store promotions, carrier activations and protection plans.",
  "Product.PriceDisclaimer.NonDevice": "* An in-store specialist will assist you with payments.",

  "Reservation.Cart": "Cart",
  "Reservation.Customer": "Customer details",
  "Reservation.Customer.FirstName": "First name",
  "Reservation.Customer.LastName": "Last name",
  "Reservation.Customer.FirstNameError": "Please enter your first name",
  "Reservation.Customer.LastNameError": "Please enter your last name",
  "Reservation.Customer.Email": "Email address",
  "Reservation.Customer.EmailError": "Please enter a valid email address",
  "Reservation.Customer.Phone": "Phone",
  "Reservation.Customer.PhoneError": "Please enter a 10-digit phone number",
  "Reservation.Customer.PhoneInputPlaceholder": "111-111-1111",
  "Reservation.Customer.Consent":
    "* I electronically authorize {company} to send me notifications about my reservation",
  "Reservation.Customer.ConsentError": "Please select to authorize notifications",
  "Reservation.Customer.Required": "* Required field",
  "Reservation.Pickup": "Pickup location",
  "Reservation.Payment": "Payment",
  "Reservation.Payment.Method": "Payment taken in store",
  "Reservation.Summary": "Reservation summary",
  "Reservation.Summary.Subtotal": "Subtotal ({count} item)",
  "Reservation.Summary.Subtotals": "Subtotal ({count} items)",
  "Reservation.Summary.Taxes": "Taxes",
  "Reservation.Summary.AdditionalFees": "Additional fee(s)",
  "Reservation.Summary.Disclaimer": "Calculated in-store",
  "Reservation.Summary.Due": "Estimated total",
  "Reservation.CompleteReservation": "Complete reservation",
  "Reservation.Breadcrumb.Reserve": "Reserve",

  "Reservation.Alert.Success.Message": "Reservation #{reservationNumber} confirmed",
  "Reservation.Alert.Success.Description":
    "A confirmation email has been sent to {email}. Another email will be sent to you when your product is ready.",
  "Reservation.Alert.NotInStock.Message": "Product is out of stock",
  "Reservation.Alert.NotInStock.Description":
    "This product is no longer in stock at this location, please select a new location on the {productPageUrl}.",
  "Reservation.Alert.InsufficientStock.Message": "Insufficient stock",
  "Reservation.Alert.InsufficientStock.Description":
    "The quantity of products you selected is no longer available at this location, please update the desired quantity or selet a new location on the {productPageUrl}.",
  "Reservation.Alert.GenericError.Message": "Failed to reserve",
  "Reservation.Alert.GenericError.Description": "Please try again.",
  "Reservation.Alert.ProductPage": "product page",

  "Location.ChangeLocation": "Change location",
  "Location.SearchBar.Prompt": "Enter new address, city, or postal code",
  "Location.SearchBar.Search": "Search",
  "Location.SearchBar.UseCurrentLocation": "Use current location",
  "Location.SearchBar.UseCurrentLocation.Blocked": "Using current location has been blocked by browser",
  "Location.SearchBar.UseCurrentLocation.Error": "Using current location has failed - please try again",
  "Location.SearchBar.PlaceHolder": "Search locations",
  "Location.SearchBar.YourLocation": "Your location",
  "Location.Search.NoResultsFound": "No results found",
  "Location.Select": "Select",
  "Location.SuggestedLocations": "Suggested locations",
  "Location.LocationsNear": "Locations near",
  "Location.LocationsNear.You": "you",
  "Location.StoreHours.Open": "Open - until {close}",
  "Location.StoreHours.ClosingSoon": "/*Closes soon*/ - {close}",
  "Location.StoreHours.Closed": "/*Closed*/ - Opens {openTime} {openDay}",
};

export default messages;
