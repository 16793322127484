import React from "react";
import { Typography } from "@iqmetrix/antd";
import styled from "styled-components";
import * as tokens from "@iqmetrix/style-tokens";
import { ColorGrey100 } from "@iqmetrix/style-tokens/dist/js/tokens-accessibility";
import { useIntl } from "../../hooks";

const { Text, Link } = Typography;

const FooterBar = styled.div`
  background: ${ColorGrey100};
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterText = styled(Text)`
  font-size: ${tokens.FontSizeBaseCaption};
  font-weight: ${tokens.FontWeightBaseCaption};
  line-height: ${tokens.LineHeightBaseCaption};
`;

export const PageFooter: React.FC = () => {
  const intl = useIntl();
  const poweredByIqmetrix = intl.formatMessage("Footer.PoweredByIqmetrix");
  const termsOfUse = intl.formatMessage("Footer.TermsOfUse");

  return (
    <FooterBar>
      <FooterText type="secondary">
        <Link type="secondary" href="https://www.iqmetrix.com/">
          {poweredByIqmetrix}
        </Link>
        {" | "}
        <Link type="secondary" href="https://www.iqmetrix.com/legal/online_reservation_storefront/terms_of_use">
          {termsOfUse}
        </Link>
      </FooterText>
    </FooterBar>
  );
};
