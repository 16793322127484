import { Configuration } from "./config";

export const LocationDetailsEndpoint = (companyId: number, locationId: number) =>
  `${Configuration.storefrontBffUrl}/companies/${companyId}/locations/${locationId}`;

export const ProductDetailsEndpoint = (companyId: number, locationId: number, catalogItemId: string) =>
  `${Configuration.storefrontBffUrl}/companies/${companyId}/locations/${locationId}/products/${catalogItemId}`;

export const ProductFeaturesEndpoint = (companyId: number, slug: string) =>
  `${Configuration.storefrontBffUrl}/companies/${companyId}/products/${slug}/features`;

export const ProductPageEntryDeprecatedEndpoint = (storeCode: string, upc: string) =>
  `${Configuration.storefrontBffUrl}/storecode/${storeCode}/upc/${upc}`;

export const ProductPageEntryEndpoint = (companyId: number, storeCode: string, upc: string) =>
  `${Configuration.storefrontBffUrl}/companies/${companyId}/storecode/${storeCode}/upc/${upc}`;

export const ProductReservationEndpoint = (companyId: number, locationId: number) =>
  `${Configuration.storefrontBffUrl}/companies/${companyId}/locations/${locationId}/reservations`;

export const LocationSearchEndpoint = (companyId: number, catalogItemId: string, originAddress: string) =>
  `${Configuration.storefrontBffUrl}/companies/${companyId}/locationSearch?catalogItemId=${catalogItemId}&originAddress=${originAddress}`;

export const PageViewEndpoint = (companyId: number, locationId: number) =>
  `${Configuration.storefrontBffUrl}/companies/${companyId}/locations/${locationId}/pageView`;
