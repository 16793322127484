import { Typography } from "@iqmetrix/antd";
import React from "react";
import styled from "styled-components";
import { useIntl } from "../../hooks";
import * as tokens from "@iqmetrix/style-tokens";

const { Text, Title } = Typography;

const PriceLabel = styled(Text)`
  font-size: ${tokens.FontSizeBaseCaption};
  margin-right: ${tokens.MarginBaseXxsmall};
  white-space: nowrap;
`;

const PriceValue = styled(Title)`
  && {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Disclaimer = styled(Text)`
  font-size: ${tokens.FontSizeBaseCaption};
  font-weight: ${tokens.FontWeightBaseCaption};
  color: ${tokens.ColorGrey400};
`;

interface ProductPriceProps {
  price: number;
  isWirelessDevice: boolean;
  useStartingAtPricing: boolean;
}

export const ProductPrice: React.FC<ProductPriceProps> = ({ price, isWirelessDevice, useStartingAtPricing }) => {
  const intl = useIntl();
  const priceText = intl.formatMessage("Product.Details.Price");
  const startingAt = intl.formatMessage("Product.Details.Price.StartingAt");
  const priceValue = intl.formatPrice(price);
  const priceDisclaimer = isWirelessDevice
    ? intl.formatMessage("Product.PriceDisclaimer.Device")
    : intl.formatMessage("Product.PriceDisclaimer.NonDevice");

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <PriceLabel strong>{`${priceText}: ${useStartingAtPricing ? startingAt : ""}`}</PriceLabel>
          </td>
          <td>
            <PriceValue level={4}>{priceValue}</PriceValue>
          </td>
        </tr>
        <tr>
          <td />
          <td>
            <Disclaimer>{priceDisclaimer}</Disclaimer>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
