import { Typography, CheckCircleOutlined, CloseCircleOutlined } from "@iqmetrix/antd";
import React from "react";
import styled from "styled-components";
import * as tokens from "@iqmetrix/style-tokens";
import { useIntl } from "../../hooks";

const { Text } = Typography;

const StockBadgeWrapper = styled(Text)<{
  visibility: "visible" | "hidden";
}>`
  white-space: nowrap;
  visibility: ${(props) => props.visibility};
`;

const InStockIcon = styled(CheckCircleOutlined)`
  color: ${tokens.ColorGreen500};
`;

const OutOfStockIcon = styled(CloseCircleOutlined)`
  color: ${tokens.ColorRed500};
`;

const StockText = styled(Text)`
  color: ${tokens.ColorGrey400};
`;

interface StockBadgeProps {
  visible?: boolean;
  inStock: boolean;
}

export const StockBadge: React.FC<StockBadgeProps> = ({ visible = true, inStock }) => {
  const intl = useIntl();
  const stockIcon = inStock ? <InStockIcon /> : <OutOfStockIcon />;
  const stockText = <StockText>{intl.formatMessage(inStock ? "Product.InStock" : "Product.SoldOut")}</StockText>;

  return (
    <StockBadgeWrapper visibility={visible ? "visible" : "hidden"}>
      {stockIcon} {stockText}
    </StockBadgeWrapper>
  );
};
