import { Address, Location, OperatingHours, StoreHours, Time } from "../models";

export function FormatPhoneNumber(phoneNumber?: string): string | undefined {
  return phoneNumber ? `${phoneNumber?.slice(0, 3)}-${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6)}` : undefined;
}

export function AddressToString(address?: Address): string | undefined {
  if (address) {
    return [
      address?.addressLine1,
      address?.addressLine2,
      address?.city,
      address?.stateCode,
      address?.countryCode,
      address?.zip,
    ]
      .filter((x) => x)
      .join(", ");
  }
}

function GetDayString(date: Date): string {
  return date.toLocaleString("en-us", { weekday: "long" }).toLowerCase();
}

export function GetTodaysHours(storeHours?: StoreHours): OperatingHours | undefined {
  if (storeHours) {
    const now = new Date();
    return storeHours[GetDayString(now) as keyof typeof storeHours];
  }
}

interface NextOpenTime {
  openTime: Time;
  openDay: string;
}

export function GetNextOpenTime(storeHours?: StoreHours): NextOpenTime | undefined {
  if (storeHours) {
    const now = new Date();

    const todaysHours = GetTodaysHours(storeHours);
    if (todaysHours?.open?.hour && now.getHours() < todaysHours.open.hour) {
      return {
        openDay: now.toLocaleString("en-us", { weekday: "short" }),
        openTime: { ...todaysHours.open },
      };
    }

    var nextOpenDate = new Date();
    var nextOpenHours: OperatingHours | undefined = undefined;
    const today = GetDayString(now);
    do {
      nextOpenDate.setDate(nextOpenDate.getDate() + 1);
      nextOpenHours = storeHours[GetDayString(nextOpenDate) as keyof typeof storeHours];
      if (!(nextOpenHours?.open && nextOpenHours?.close)) {
        nextOpenHours = undefined;
      }
    } while (nextOpenHours === undefined && GetDayString(nextOpenDate) !== today);

    if (!!nextOpenHours && !!nextOpenHours.open) {
      return {
        openDay: nextOpenDate.toLocaleString("en-us", { weekday: "short" }),
        openTime: {
          hour: nextOpenHours.open.hour,
          minute: nextOpenHours.open.minute,
        },
      };
    }
  }
  return undefined;
}

export type OpenStatus = "Open" | "Closed" | "ClosingSoon";

const hourInMilliseconds = 1000 * 60 * 60;
export function GetLocationOpenStatus(location: Location): OpenStatus {
  const todaysHours = GetTodaysHours(location.storeHours);
  if (!!todaysHours?.open?.hour && !!todaysHours?.close?.hour) {
    const now = new Date();

    let openTime = new Date();
    openTime.setHours(todaysHours.open.hour, todaysHours.open.minute);

    let closeTime = new Date();
    closeTime.setHours(todaysHours.close.hour, todaysHours.close.minute);

    // store is open
    if (now >= openTime && now < closeTime) {
      if (closeTime.getTime() - now.getTime() <= hourInMilliseconds) {
        return "ClosingSoon";
      }
      return "Open";
    }
  }

  return "Closed";
}
