import React, { createContext, useReducer } from "react";
import { Location } from "../models";

type Action = { type: "empty" } | { type: "update"; locationDetails: Location };

export interface LocationState {
  locationDetails?: Location;
}

const initialState: LocationState = {
  locationDetails: undefined,
};

function reducer(state: LocationState, action: Action): LocationState {
  switch (action.type) {
    case "empty":
      return { ...initialState };
    case "update":
      return {
        ...state,
        locationDetails: action.locationDetails,
      };
    default:
      return { ...state };
  }
}

interface LocationStateProps {
  locationState: LocationState;
  dispatch: React.Dispatch<Action>;
}

interface LocationProviderProps {
  children: React.ReactNode;
}

export const LocationContext = createContext<LocationStateProps>({ locationState: initialState, dispatch: () => {} });

export const LocationProvider = ({ children }: LocationProviderProps) => {
  const [locationState, dispatch] = useReducer(reducer, initialState);
  return <LocationContext.Provider value={{ locationState, dispatch }}>{children}</LocationContext.Provider>;
};
