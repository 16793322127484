import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { useLocationDetails } from "../location";
import { useProductPageView } from ".";
import { UserContext } from "../../contexts";

interface ProductRouteProps extends RouteComponentProps {
  catalogItemId?: string;
}

export const Product: React.FC<ProductRouteProps> = ({ catalogItemId, children }) => {
  const { locationDetails } = useLocationDetails();
  const { companyId, locationId } = { ...locationDetails };
  const { isInternalUser } = useContext(UserContext);
  const { sendPageView } = useProductPageView();

  useEffect(() => {
    const callSendPageView = async () => {
      if (isInternalUser === false && companyId && locationId && catalogItemId) {
        sendPageView(companyId, locationId, catalogItemId);
      }
    };
    callSendPageView();
  }, [isInternalUser, companyId, locationId, catalogItemId, sendPageView]);

  return <>{children}</>;
};
