import { Configuration } from "../../constants/config";
import { useCallback } from "react";
import { PageViewEndpoint } from "../../constants/endpoints";
import { useSessionStorage } from "../../hooks";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const useProductPageView = () => {
  const { saveCatalogItem, loadCatalogItem } = useSessionStorage();
  const currentCatalogItemId = loadCatalogItem();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendPageView = useCallback(
    async (companyId: number, locationId: number, catalogItemId: string) => {
      if (catalogItemId !== currentCatalogItemId) {
        saveCatalogItem(catalogItemId);

        setTimeout(async () => {
          const pageViewBody = { catalogItemId };
          if (executeRecaptcha) {
            const token = await executeRecaptcha(Configuration.reCaptchaActionNameForPageView);
            if (token) {
              await fetch(PageViewEndpoint(companyId, locationId), {
                method: "POST",
                body: JSON.stringify(pageViewBody),
                headers: { "Content-Type": "application/json", accept: "text/plain", token: token },
              });
            }
          }
        }, Configuration.pageViewTimeout);
      }
    },
    [currentCatalogItemId, saveCatalogItem, executeRecaptcha]
  );

  return { sendPageView };
};
