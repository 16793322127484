import React from "react";
import { Space, Typography, Row, Col } from "@iqmetrix/antd";
import styled from "styled-components";
import { ColorGrey300, PaddingBaseXxsmall, PaddingBaseXsmall } from "@iqmetrix/style-tokens";
import { SpecificationGroup, Specification } from "../../models";
import DOMPurify from "dompurify";
import { Configuration } from "../../constants/config";

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

const SpecificationsList = styled.div`
  display: flex;
  flex-direction: column;

  //remove top borders from all but the first row
  .ant-row:nth-child(n + 2) {
    .ant-col > div:first-child {
      border-top-width: 0px;
    }
  }
`;

const ColContent = styled.div`
  padding: ${PaddingBaseXxsmall} ${PaddingBaseXsmall};
  height: 100%;
  border: 1px solid ${ColorGrey300};
`;

interface SpecificationProps {
  displayName: string;
  value: string;
}

interface SpecificationGroupProps {
  name: string;
  fields: Specification[];
}

interface ProductSpecificationsProps {
  specifications: SpecificationGroup[];
}

const SpecificationView: React.FC<SpecificationProps> = ({ displayName, value }) => {
  const isCarrierField = Configuration.carrierFieldNames.includes(displayName);
  const valueToDisplay = isCarrierField
    ? value
        .split(",")
        .map((i) => i.substring(i.indexOf("|") + 1))
        .join(", ")
    : value;

  return (
    <Row gutter={16}>
      <Col span={12}>
        <ColContent>
          <Typography.Text>{displayName}</Typography.Text>
        </ColContent>
      </Col>
      <Col span={12}>
        <ColContent>
          <Typography.Text>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(valueToDisplay),
              }}
            ></div>
          </Typography.Text>
        </ColContent>
      </Col>
    </Row>
  );
};

const SpecificationGroupView: React.FC<SpecificationGroupProps> = ({ name, fields }) => {
  return (
    <FullWidthSpace direction="vertical">
      <Typography.Text strong>{name}</Typography.Text>
      <SpecificationsList>
        {fields.map((spec) => (
          <SpecificationView {...spec} key={spec.displayName} />
        ))}
      </SpecificationsList>
    </FullWidthSpace>
  );
};

export const ProductSpecifications: React.FC<ProductSpecificationsProps> = ({ specifications }) => {
  return (
    <Row justify={"start"}>
      <Col xs={24} sm={20} md={16}>
        <FullWidthSpace direction="vertical" size="middle">
          {specifications.map((group) => (
            <SpecificationGroupView {...group} key={group.name} />
          ))}
        </FullWidthSpace>
      </Col>
    </Row>
  );
};
