import { useCallback } from "react";
import { Location, LocationMap } from "../../models";

const CATALOG_ITEM_KEY = "catalog_item";
const LOCATION_MAP_KEY = "location_map";

const save = (key: string, value: any) => {
  console.debug(`Saving to session storage: ${JSON.stringify(value)}`);
  sessionStorage.setItem(key, JSON.stringify(value));
};

const load = <T>(key: string): T | undefined => {
  const item = sessionStorage.getItem(key);
  return !!item ? (JSON.parse(item) as T) : undefined;
};

const clear = (key: string) => {
  sessionStorage.removeItem(key);
};

const useSessionStorage = () => {
  const saveLocation = useCallback((location: Location) => {
    const prev = load<LocationMap>(LOCATION_MAP_KEY);
    save(LOCATION_MAP_KEY, {
      ...prev,
      [location.companyId]: {
        ...prev?.[location.companyId],
        [location.locationId]: location,
      },
    });
  }, []);

  const loadLocation = useCallback((companyId: number, locationId: number) => {
    const map = load<LocationMap>(LOCATION_MAP_KEY);
    return map?.[companyId]?.[locationId];
  }, []);

  const saveCatalogItem = useCallback((catalogItemId: string) => {
    save(CATALOG_ITEM_KEY, catalogItemId);
  }, []);

  const loadCatalogItem = useCallback(() => {
    return load<string>(CATALOG_ITEM_KEY);
  }, []);

  const clearCatalogItem = useCallback(() => {
    clear(CATALOG_ITEM_KEY);
  }, []);

  return {
    saveLocation,
    loadLocation,
    saveCatalogItem,
    loadCatalogItem,
    clearCatalogItem,
  };
};

export { useSessionStorage };
