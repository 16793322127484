import { Button, Form, InputNumber, Row } from "@iqmetrix/antd";
import * as tokens from "@iqmetrix/style-tokens";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useIntl } from "../../hooks";

const Container = styled(Row)`
  display: flex;
  align-items: flex-end;
  column-gap: ${tokens.SpacingBaseSmall};
  margin-bottom: ${tokens.SpacingBaseBase};
  .ant-row {
    margin: 0px;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 57px;
  height: ${tokens.HeightInputBase};
`;

export interface ProductReservationProps {
  quantityOnHand: number;
  onReserve: (quantity: number) => void;
}

export const ProductReservation: React.FC<ProductReservationProps> = ({ quantityOnHand, onReserve }) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const min = quantityOnHand > 0 ? 1 : 0;
  const max = quantityOnHand > 0 ? quantityOnHand : 0;

  const initialValues = {
    quantity: min,
  };

  const onFinish = useCallback(() => {
    const quantity = form.getFieldValue("quantity") as number;
    if (quantity) onReserve(quantity);
  }, [form, onReserve]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} initialValues={initialValues}>
      <Container>
        <Form.Item label={intl.formatMessage("Product.ReserveQuantity")} name="quantity">
          <StyledInputNumber type="number" min={min} max={max} disabled={quantityOnHand <= 0} />
        </Form.Item>
        <Form.Item>
          <Button disabled={quantityOnHand <= 0} type="primary" htmlType="submit">
            {intl.formatMessage("Product.ReserveButton")}
          </Button>
        </Form.Item>
      </Container>
    </Form>
  );
};
