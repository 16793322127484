import { Card, Empty, List, Skeleton } from "@iqmetrix/antd";
import * as tokens from "@iqmetrix/style-tokens";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useIntl, useLocale } from "../../../hooks";
import { SearchedLocation, Location } from "../../../models";
import { GetLocationOpenStatus, GetMetersFromDistance, OpenStatus } from "../../../utilities";
import { SearchedLocationDetails } from "./";

const Container = styled.div`
  height: 100%;

  .ant-card-head {
    z-index: 1000;
    margin-bottom: 1px;
    box-shadow: ${(props: { showShadowBox: boolean }) => {
      return props.showShadowBox ? tokens.BoxShadowBaseBase : "none";
    }};
  }
`;

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-card-head-title {
    white-space: normal;
    padding-top: ${tokens.PaddingBaseBase};
    padding-bottom: ${tokens.PaddingBaseBase};
  }

  .ant-card-body {
    overflow-y: hidden;
    padding: 0px 0px 0px ${tokens.PaddingCardBase};
    height: 100%;
  }
`;

const ListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0px ${tokens.PaddingCardBase} ${tokens.PaddingCardBase} 0px;

  /* The following are needed to center Empty when no results */
  .ant-list,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-list-empty-text {
    height: 100%;
  }
`;

const CenteredEmpty = styled(Empty)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function SortByDistance(locationX: SearchedLocation, locationY: SearchedLocation) {
  if (Number.isInteger(locationX.distance) && Number.isInteger(locationY.distance)) {
    return locationX.distance! - locationY.distance!;
  }
  return 0;
}

interface SearchedLocationCardProps {
  locations: SearchedLocation[];
  isLoading: boolean;
  searchText?: string;
  onSelect: (location: Location) => void;
}

const loadingArray = [0, 1, 2];
const LoadingSkeletons: React.FC = () => {
  return (
    <List
      dataSource={loadingArray}
      renderItem={(x) => (
        <List.Item>
          <Skeleton active title={false} paragraph={{ rows: 3 }} key={`Skeleton${x}`} />
        </List.Item>
      )}
    />
  );
};

export const SearchedLocationsCard: React.FC<SearchedLocationCardProps> = ({
  locations,
  isLoading,
  searchText,
  onSelect,
}) => {
  const intl = useIntl();
  const locale = useLocale();
  const maxDistanceInMeters = GetMetersFromDistance(25, locale.unitSystem);
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  const onScroll = useCallback((event: any) => {
    setShowBoxShadow(event.currentTarget.scrollTop > 0);
  }, []);

  var modifiedLocations = locations
    .filter((x) => Number.isInteger(x.distance) && x.distance! <= maxDistanceInMeters)
    .map<{
      searchedLocation: SearchedLocation;
      isAvailable: boolean;
      openStatus: OpenStatus;
    }>((x) => {
      return {
        searchedLocation: x,
        isAvailable: x.productAvailability === "InStock",
        openStatus: GetLocationOpenStatus(x.location),
      };
    });

  const sortedLocations = ([] as { searchedLocation: SearchedLocation; openStatus: OpenStatus }[])
    .concat(
      modifiedLocations
        .filter((x) => x.isAvailable && x.openStatus !== "Closed")
        .sort((a, b) => SortByDistance(a.searchedLocation, b.searchedLocation))
    )
    .concat(
      modifiedLocations
        .filter((x) => x.isAvailable && x.openStatus === "Closed")
        .sort((a, b) => SortByDistance(a.searchedLocation, b.searchedLocation))
    )
    .concat(
      modifiedLocations
        .filter((x) => !x.isAvailable)
        .sort((a, b) => SortByDistance(a.searchedLocation, b.searchedLocation))
    );

  const cardTitle = searchText
    ? `${intl.formatMessage("Location.LocationsNear")} ${searchText}`
    : intl.formatMessage("Location.SuggestedLocations");

  const noResultsText = intl.formatMessage("Location.Search.NoResultsFound");

  return (
    <Container showShadowBox={showBoxShadow}>
      <StyledCard title={cardTitle}>
        <ListContainer onScroll={onScroll}>
          {isLoading ? (
            <LoadingSkeletons />
          ) : (
            <List
              locale={{ emptyText: <CenteredEmpty description={noResultsText} /> }}
              dataSource={sortedLocations}
              renderItem={(sortedLocation) => (
                <List.Item>
                  <SearchedLocationDetails
                    key={sortedLocation.searchedLocation.location.locationId}
                    searchedLocation={sortedLocation.searchedLocation}
                    showDistance={!!searchText}
                    onSelect={onSelect}
                  />
                </List.Item>
              )}
            />
          )}
        </ListContainer>
      </StyledCard>
    </Container>
  );
};
