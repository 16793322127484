import { useIntl as useReactIntl } from "react-intl";
import { Messages } from "../../shared";

export function useIntl() {
  const useIntl = useReactIntl();

  return {
    ...useIntl,
    formatMessage: (id: keyof Messages, values?: any) => {
      return useIntl.formatMessage({ id }, values);
    },
    convertTime: (hour: number, minute: number) => {
      const today = new Date();
      today.setHours(hour);
      today.setMinutes(minute);
      return useIntl.formatTime(today);
    },
    formatPrice: (price: number) => {
      return useIntl.formatNumber(price, { style: "currency", currency: "CAD", currencyDisplay: "narrowSymbol" });
    },
  };
}
