import { ProductDetailsEndpoint } from "../../constants/endpoints";
import { useCallback, useReducer } from "react";
import { ProductDetails } from "../../models";
import { ResultStatusErrorType } from "@iqmetrix/antd";

type Action =
  | { type: "load" }
  | { type: "success"; productDetails: ProductDetails }
  | { type: "error"; statusCode: ResultStatusErrorType };

export type ProductDetailsStatus = "empty" | "loading" | "success" | "error";

export type ProductDetailsState = {
  status: ProductDetailsStatus;
  productDetails: ProductDetails;
  errorType?: ResultStatusErrorType;
};

function reducer(state: ProductDetailsState, action: Action): ProductDetailsState {
  switch (action.type) {
    case "load":
      return { ...state, status: "loading" };
    case "success":
      return { ...state, status: "success", productDetails: action.productDetails };
    case "error":
      return { ...state, status: "error", errorType: action.statusCode };

    default:
      return { ...state };
  }
}

const initialState: ProductDetailsState = {
  status: "empty",
  productDetails: {} as ProductDetails,
};

export const useProductDetailsRequest = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchProductDetails = useCallback(
    async (companyId: number, locationId: number, catalogItemId: string) => {
      dispatch({ type: "load" });
      try {
        const detailsResult = await fetch(ProductDetailsEndpoint(companyId, locationId, catalogItemId), {
          headers: { "Content-Type": "application/json", accept: "text/plain" },
        });
        if (!detailsResult.ok) throw detailsResult;
        const productDetails = (await detailsResult.json()) as ProductDetails;

        dispatch({ type: "success", productDetails });
      } catch (error: any) {
        if (error instanceof Response) {
          dispatch({ type: "error", statusCode: error.status.toString() as ResultStatusErrorType });
        } else {
          dispatch({ type: "error", statusCode: "500" });
        }
      }
    },
    [dispatch]
  );

  return { state, fetchProductDetails };
};
