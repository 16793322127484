import { Button, Card, EnvironmentFilled, Input, SearchOutlined, Typography } from "@iqmetrix/antd";
import * as tokens from "@iqmetrix/style-tokens";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../hooks";
const { Text } = Typography;

interface SearchBarCardProps {
  disabled?: boolean;
  onSearchText: (searchText: string) => void;
  onSearchCoords: (coords: GeolocationCoordinates) => void;
  onClear: () => void;
}

const ActionItems = styled.div`
  display: flex;
  justify-content: ${(props: { hasMultipleChildren: boolean }) =>
    props.hasMultipleChildren ? "space-between" : "end"};
  margin-top: ${tokens.MarginBaseBase};
`;

const CurrentLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocationButton = styled(Button)`
  padding-left: 0;
  border-width: 0;
`;

const ErrorText = styled(Text)`
  color: ${tokens.ColorTextError};
  font-size: ${tokens.FontSizeBaseCaption};
  margin-top: -${tokens.PaddingBaseXsmall};
`;

enum LocationStatus {
  "Unknown",
  "Unsupported",
  "Checking",
  "Blocked",
  "Error",
  "Allowed",
}

export const SearchBarCard: React.FC<SearchBarCardProps> = ({ disabled, onSearchText, onSearchCoords, onClear }) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState<string>();
  const [_, setStatus] = useState<LocationStatus>(LocationStatus.Unknown);
  const [locationError, setLocationError] = useState<string | undefined>();

  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.currentTarget.value;
      setSearchValue(text);
      if (!text) {
        onClear();
        event.currentTarget.blur();
      }
    },
    [onClear]
  );

  const onSearchAction = useCallback(() => {
    if (searchValue) {
      onSearchText(searchValue);
    }
  }, [onSearchText, searchValue]);

  const currentLocationSupported = !!navigator.geolocation;

  const onUseCurrentLocation = useCallback(() => {
    if (!currentLocationSupported) {
      setStatus(LocationStatus.Unsupported);
    } else {
      setStatus(LocationStatus.Checking);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(LocationStatus.Allowed);
          setSearchValue(intl.formatMessage("Location.SearchBar.YourLocation"));
          setLocationError(undefined);
          onSearchCoords(position.coords);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setStatus(LocationStatus.Blocked);
            setLocationError(intl.formatMessage("Location.SearchBar.UseCurrentLocation.Blocked"));
          } else {
            setStatus(LocationStatus.Error);
            setLocationError(intl.formatMessage("Location.SearchBar.UseCurrentLocation.Error"));
          }
        }
      );
    }
  }, [currentLocationSupported, intl, onSearchCoords]);

  return (
    <Card>
      <Text>{intl.formatMessage("Location.SearchBar.Prompt")}</Text>
      <Input
        allowClear
        prefix={<SearchOutlined />}
        onChange={onSearchTextChanged}
        onPressEnter={onSearchAction}
        placeholder={intl.formatMessage("Location.SearchBar.PlaceHolder")}
        disabled={disabled}
        value={searchValue}
      />
      <ActionItems hasMultipleChildren={currentLocationSupported}>
        {currentLocationSupported && (
          <CurrentLocationContainer>
            <LocationButton type="link" onClick={onUseCurrentLocation} disabled={disabled} icon={<EnvironmentFilled />}>
              {intl.formatMessage("Location.SearchBar.UseCurrentLocation")}
            </LocationButton>
            {locationError && <ErrorText>{locationError}</ErrorText>}
          </CurrentLocationContainer>
        )}
        <Button type="primary" onClick={onSearchAction} disabled={disabled}>
          {intl.formatMessage("Location.SearchBar.Search")}
        </Button>
      </ActionItems>
    </Card>
  );
};
