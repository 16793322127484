import "@iqmetrix/antd/dist/accessibility.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import * as packageJson from "../package.json";
import { App } from "./App";
import { ErrorBoundary, I18nProvider } from "./components";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { Configuration } from "./constants/config";
import { LocationProvider, ReservationProvider, UserProvider } from "./contexts";
import * as FullStory from "@fullstory/browser";
import TagManager from "react-gtm-module";
import { GoogleTagManagerArgs } from "./shared";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

async function init() {
  if (process.env.NODE_ENV !== "development") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: Configuration.environment,
      attachStacktrace: true,
      release: `${packageJson.name}@${packageJson.version}`,
      integrations: [new Integrations.BrowserTracing()],
      ignoreErrors: [
        // Ignore errors that are unactionable. These are due to the user's network.
        "TypeError: Failed to fetch",
        "TypeError: NetworkError when attempting to fetch resource.",
        "TypeError: Cancelled",
      ],
    });

    FullStory.init({ orgId: Configuration.fullstoryOrgId });
    FullStory.setVars("page", {
      pageName: "Storefront",
      app: Configuration.appName,
      environment: Configuration.environment,
    });
  }

  TagManager.initialize(GoogleTagManagerArgs);

  const Application = () => (
    <React.StrictMode>
      <ErrorBoundary>
        <I18nProvider>
          <GoogleReCaptchaProvider reCaptchaKey={Configuration.recaptchaKey}>
            <UserProvider>
              <LocationProvider>
                <ReservationProvider>
                  <App />
                </ReservationProvider>
              </LocationProvider>
            </UserProvider>
          </GoogleReCaptchaProvider>
        </I18nProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );

  ReactDOM.render(<Application />, document.getElementById("root"));

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
}

init();
