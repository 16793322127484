const Paths = {
  NotFound: "/",
  PointyEntryDeprecated: "/pointy-product/:storeCode/:upc",
  PointyEntry: "/companies/:companyId/pointy-product/:storeCode/:upc",
  Product: "/location/:companyId/:locationId/product/:catalogItemId",
  Reservation: "/location/:companyId/:locationId/product/:catalogItemId/reserve",
} as const;

type Path = typeof Paths[keyof typeof Paths];

/**
 * Formats one of the paths in {@link Paths} with parameters taken from the properties in `formatObj`.
 * @example
 * ```
 * // Prints "/pointy-product/foo/bar"
 * console.log(FormatPath("/pointy-product/:storeCode/:upc", {storeCode: "foo", upc: "bar"}));
 * ```
 */
const FormatPath = (path: Path, formatObj: any): string => {
  const addParamToPath = (formattedPath: string, param: string) => formattedPath.replace(`:${param}`, formatObj[param]);
  return Object.keys(formatObj).reduce(addParamToPath, path);
};

export { Paths, FormatPath };
