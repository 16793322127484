import { useContext, useMemo } from "react";
import { LocationContext } from "../../contexts";
import { DistanceUnit, UnitSystem } from "../../shared";

export interface Locale {
  unitSystem: UnitSystem;
  distanceUnit: DistanceUnit;
}

export const useLocale = (): Locale => {
  const locationContext = useContext(LocationContext);

  const unitSystem = useMemo<UnitSystem>(() => {
    return locationContext.locationState.locationDetails?.address?.countryCode?.toLowerCase() === "us"
      ? "imperial"
      : "metric";
  }, [locationContext.locationState.locationDetails?.address?.countryCode]);

  const distanceUnit = useMemo<DistanceUnit>(() => {
    return unitSystem === "imperial" ? "mi" : "km";
  }, [unitSystem]);

  return {
    unitSystem,
    distanceUnit,
  };
};
