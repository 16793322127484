import { Col, Row, Typography } from "@iqmetrix/antd";
import * as tokens from "@iqmetrix/style-tokens";
import { ColorGrey100 } from "@iqmetrix/style-tokens/dist/js/tokens-accessibility";
import React from "react";
import styled from "styled-components";
import { useLocationDetails } from "../../pages/location";
import { ResponsiveColumnsHorizontal } from "../../shared";

const HeaderBar = styled.div`
  background-color: ${ColorGrey100};
  height: 56px;
`;

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const HeaderText = styled(Typography.Text)`
  font-size: ${tokens.FontSizeBaseHeading};
  font-weight: ${tokens.FontWeightBaseHeading};
  line-height: ${tokens.LineHeightBaseHeading};
`;

export const PageHeader: React.FC = () => {
  const { locationDetails } = useLocationDetails();

  return (
    <HeaderBar>
      <FullHeightRow justify="center">
        <StyledCol {...ResponsiveColumnsHorizontal}>
          {locationDetails && (
            <HeaderText>{`${locationDetails.companyName} - ${locationDetails.locationName}`}</HeaderText>
          )}
        </StyledCol>
      </FullHeightRow>
    </HeaderBar>
  );
};
