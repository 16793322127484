import React, { createContext, useReducer } from "react";
import { ProductDetails, Reservation } from "../models";

type Action =
  | { type: "empty" }
  | { type: "update"; reservation: Reservation }
  | { type: "updateProductDetails"; details: ProductDetails };

export interface ReservationState {
  reservation?: Reservation;
}

const initialState = {
  reservation: undefined,
};

function reducer(state: ReservationState, action: Action): ReservationState {
  switch (action.type) {
    case "empty":
      return { ...initialState };
    case "update":
      return { ...state, reservation: action.reservation };
    case "updateProductDetails":
      return {
        ...state,
        reservation: state.reservation && {
          ...state.reservation,
          product: {
            ...state.reservation.product,
            details: action.details,
          },
        },
      };
    default:
      return { ...state };
  }
}

interface ReservationStateProps {
  reservationState: ReservationState;
  dispatch: React.Dispatch<Action>;
}

interface ReservationProviderProps {
  children: React.ReactNode;
}

export const ReservationContext = createContext<ReservationStateProps>({
  reservationState: initialState,
  dispatch: () => {},
});

export const ReservationProvider = ({ children }: ReservationProviderProps) => {
  const [reservationState, dispatch] = useReducer(reducer, initialState);
  return <ReservationContext.Provider value={{ reservationState, dispatch }}>{children}</ReservationContext.Provider>;
};
