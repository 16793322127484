import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import * as tokens from "@iqmetrix/style-tokens";
import { Drawer, Modal, Space } from "@iqmetrix/antd";
import { SearchedLocationsCard, useLocationSearch, SearchBarCard } from ".";
import { useIntl, useSessionStorage } from "../../../hooks";
import { useLocationDetails } from "../../location";
import { AddressToString } from "../../../utilities";
import { Location } from "../../../models";

const StyledModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    height: 80vh;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
  }
`;

const Container = styled(Space)`
  div.ant-space-item:last-of-type {
    flex-grow: 1;
    overflow: hidden;
  }
`;

interface ChangeLocationModalProps {
  visible: boolean;
  onDismiss: () => void;
}

const ModalContents: React.FC<ChangeLocationModalProps> = ({ onDismiss }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { catalogItemId } = useParams();
  const { saveLocation } = useSessionStorage();
  const { locationDetails } = useLocationDetails();
  const { companyId, locationId } = { ...locationDetails };
  const { state, search } = useLocationSearch();
  const { status, locations } = state;
  const [searchText, setSearchText] = useState<string>();

  const defaultSearch = useCallback(() => {
    const address = AddressToString(locationDetails?.address);
    if (!!companyId && !!catalogItemId && address) {
      search(companyId, catalogItemId, address);
    }
  }, [catalogItemId, companyId, locationDetails, search]);

  useEffect(() => {
    if (status === "empty") {
      defaultSearch();
    }
  }, [defaultSearch, status]);

  const onSearchText = useCallback(
    (searchText: string) => {
      if (!companyId || !searchText) return;
      setSearchText(searchText);
      search(companyId, catalogItemId, searchText);
    },
    [catalogItemId, companyId, search]
  );

  const onSearchCoords = useCallback(
    (coords: GeolocationCoordinates) => {
      if (!companyId || !coords) return;
      setSearchText(intl.formatMessage("Location.LocationsNear.You"));
      search(companyId, catalogItemId, `${coords.latitude},${coords.longitude}`);
    },
    [catalogItemId, companyId, intl, search]
  );

  const onClear = useCallback(() => {
    if (searchText) {
      setSearchText(undefined);
      defaultSearch();
    }
  }, [defaultSearch, searchText]);

  const onLocationSelected = useCallback(
    (location: Location) => {
      saveLocation(location);
      navigate(pathname.replace(/\/location\/\d+\/\d+\//i, `/location/${location.companyId}/${location.locationId}/`));
      onDismiss();
    },
    [pathname, onDismiss, saveLocation, navigate]
  );

  const otherLocations = locations.filter((x) => x.location.locationId !== locationId);
  return (
    <Container direction="vertical" size={20}>
      <SearchBarCard
        onSearchText={onSearchText}
        onSearchCoords={onSearchCoords}
        onClear={onClear}
        disabled={state.status === "loading"}
      />
      <SearchedLocationsCard
        locations={otherLocations}
        isLoading={status === "empty" || status === "loading"}
        searchText={searchText}
        onSelect={onLocationSelected}
      />
    </Container>
  );
};

export const ChangeLocationModal: React.FC<ChangeLocationModalProps> = (props) => {
  const intl = useIntl();
  const isMobile = window.matchMedia(tokens.MediaQueriesXsmall).matches;
  const modalContent = <ModalContents {...props} />;

  return isMobile ? (
    <StyledDrawer
      title={intl.formatMessage("Location.ChangeLocation")}
      placement="right"
      visible={props.visible}
      onClose={props.onDismiss}
      contentWrapperStyle={{ width: "100%" }}
      children={modalContent}
    />
  ) : (
    <StyledModal
      title={intl.formatMessage("Location.ChangeLocation")}
      visible={props.visible}
      centered={true}
      onCancel={props.onDismiss}
      destroyOnClose={true}
      footer={null}
      children={modalContent}
    />
  );
};
