import { LocationSearchEndpoint } from "../../../constants/endpoints";
import { useCallback, useReducer, useState } from "react";
import { SearchedLocation } from "../../../models";
import { ResultStatusErrorType } from "@iqmetrix/antd";

type Action =
  | { type: "load" }
  | { type: "success"; locations: SearchedLocation[] }
  | { type: "error"; statusCode: ResultStatusErrorType };

export type LocationSearchStatus = "empty" | "loading" | "success" | "error";

export type LocationSearchState = {
  status: LocationSearchStatus;
  locations: SearchedLocation[];
  errorType?: ResultStatusErrorType;
};

function reducer(state: LocationSearchState, action: Action): LocationSearchState {
  switch (action.type) {
    case "load":
      return { ...state, status: "loading" };
    case "success":
      return { ...state, status: "success", locations: action.locations };
    case "error":
      return { ...state, status: "error", errorType: action.statusCode };

    default:
      return { ...state };
  }
}

const initialState: LocationSearchState = {
  status: "empty",
  locations: [] as SearchedLocation[],
};

export const useLocationSearch = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchResults, setSearchResults] = useState<{ [searchText: string]: SearchedLocation[] }>({});

  const search = useCallback(
    async (companyId: number, catalogItemId: string, originAddress: string) => {
      dispatch({ type: "load" });
      if (searchResults[originAddress]) {
        // Timeout is added in order to sit in the load state a bit to provide feedback to the user that the results have been updated.
        setTimeout(() => {
          dispatch({ type: "success", locations: [...searchResults[originAddress]] });
        }, 250);
      } else {
        try {
          const locationSearchResult = await fetch(LocationSearchEndpoint(companyId, catalogItemId, originAddress));
          if (!locationSearchResult.ok) throw locationSearchResult;
          const locations = (await locationSearchResult.json()) as SearchedLocation[];
          setSearchResults((current) => {
            return { ...current, [originAddress]: locations };
          });
          dispatch({ type: "success", locations });
        } catch (error: any) {
          if (error instanceof Response) {
            dispatch({ type: "error", statusCode: error.status.toString() as ResultStatusErrorType });
          } else {
            dispatch({ type: "error", statusCode: "500" });
          }
        }
      }
    },
    [searchResults]
  );

  return { state, search };
};
