import { Card, Form, Input, Typography, Space, Checkbox } from "@iqmetrix/antd";
import React from "react";
import MaskedInput from "antd-mask-input";
import styled from "styled-components";
import { useIntl } from "../../hooks";
import { useLocationDetails } from "../location";
import * as tokens from "@iqmetrix/style-tokens";

const { Text } = Typography;

const phoneNumberPattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

const FullWidthSpace = styled(Space)`
  .ant-form-item-label {
    > label.ant-form-item-required:before {
      color: black;
    }
  }
  && {
    display: flex;
    width: 100%;
  }
`;

const RequiredText = styled(Text)`
  display: flex;
  justify-content: right;
  font-size: ${tokens.FontSizeBaseCaption};
  font-weight: ${tokens.FontWeightBaseCaption};
  color: ${tokens.ColorGrey400};
`;

const BaseFormItem = styled(Form.Item)`
  && {
    @media (max-width: ${tokens.WidthScreenMaxSmall}) {
      max-width: 100%;
    }
  }
`;

const Name = styled(BaseFormItem)`
  max-width: 237px; ;
`;

const Phone = styled(BaseFormItem)`
  max-width: 277px;
`;

const FullWidthItem = styled(BaseFormItem)`
  max-width: 481px;
`;

const CustomerName = styled.div`
  display: flex;
  gap: ${tokens.SpacingBaseXsmall};

  @media (max-width: ${tokens.WidthScreenMaxSmall}) {
    flex-direction: column;
  }
`;

interface CustomerDetailsCardProps {
  disabled?: boolean;
}

export const CustomerDetailsCard: React.FC<CustomerDetailsCardProps> = ({ disabled }) => {
  const intl = useIntl();
  const { locationDetails } = useLocationDetails();

  return (
    <Card title={intl.formatMessage("Reservation.Customer")}>
      <FullWidthSpace direction="vertical" size={12}>
        <CustomerName>
          <Name
            label={intl.formatMessage("Reservation.Customer.FirstName")}
            name="firstName"
            rules={[
              {
                required: true,
                message: intl.formatMessage("Reservation.Customer.FirstNameError"),
              },
            ]}
          >
            <Input type="text" autoFocus={true} disabled={disabled} />
          </Name>
          <Name
            label={intl.formatMessage("Reservation.Customer.LastName")}
            name="lastName"
            rules={[
              {
                required: true,
                message: intl.formatMessage("Reservation.Customer.LastNameError"),
              },
            ]}
          >
            <Input type="text" disabled={disabled} />
          </Name>
        </CustomerName>
        <FullWidthItem
          label={intl.formatMessage("Reservation.Customer.Email")}
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: intl.formatMessage("Reservation.Customer.EmailError"),
            },
          ]}
        >
          <Input type="email" disabled={disabled} />
        </FullWidthItem>
        <Phone
          label={intl.formatMessage("Reservation.Customer.Phone")}
          name="phoneNumber"
          rules={[
            {
              required: true,
              pattern: phoneNumberPattern,
              message: intl.formatMessage("Reservation.Customer.PhoneError"),
            },
          ]}
        >
          <MaskedInput mask="111-111-1111" disabled={disabled} />
        </Phone>
        <FullWidthItem
          name="consent"
          valuePropName="checked"
          rules={[
            {
              required: true,
              transform: (value) => value || undefined,
              type: "boolean",
              message: intl.formatMessage("Reservation.Customer.ConsentError"),
            },
          ]}
        >
          <Checkbox disabled={disabled}>
            {`${intl.formatMessage("Reservation.Customer.Consent", {
              company: `${locationDetails?.companyName}`,
            })}`}
          </Checkbox>
        </FullWidthItem>
        <RequiredText>{`${intl.formatMessage("Reservation.Customer.Required")}`}</RequiredText>
      </FullWidthSpace>
    </Card>
  );
};
