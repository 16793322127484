import React from "react";
import styled from "styled-components";
import { useLocale } from "../../hooks";
import { Location } from "../../models";
import { FormatPhoneNumber, GetDistanceInDesiredUnits } from "../../utilities";
import { Typography } from "@iqmetrix/antd";
import * as tokens from "@iqmetrix/style-tokens";
import { LocationHours } from "./";
const { Text } = Typography;

const NameText = styled(Text)`
  font-weight: 700;
`;

const DistanceText = styled(Text)`
  padding-left: ${tokens.PaddingBaseBase};
`;

interface LocationDetailsProps {
  location?: Location;
  distance?: number;
}

export const LocationDetails: React.FC<LocationDetailsProps> = ({ location, distance }) => {
  const locale = useLocale();

  if (!location) {
    return null;
  }

  const distanceText: string | undefined = Number.isInteger(distance)
    ? `${GetDistanceInDesiredUnits(distance!, locale.unitSystem).toFixed(1)} ${locale.distanceUnit}`
    : undefined;

  return (
    <>
      <NameText>
        {location.companyName} - {location.locationName}
      </NameText>
      {location.address?.addressLine1 && (
        <div>
          <Text>{location.address.addressLine1}</Text>
          {distanceText && <DistanceText>{distanceText}</DistanceText>}
        </div>
      )}
      {(location.address?.city || location.address?.stateCode) && (
        <Text>{`${location.address.city}, ${location.address.stateCode}`}</Text>
      )}
      {location.phoneNumber && (
        <div>
          <a type="link" href={"tel:" + location.phoneNumber}>
            <Text>{FormatPhoneNumber(location.phoneNumber)}</Text>
          </a>
        </div>
      )}
      <LocationHours location={location} />
    </>
  );
};
