import { ProductPageEntryDeprecatedEndpoint, ProductPageEntryEndpoint } from "../../constants/endpoints";
import { useEffect, useReducer } from "react";
import { ProductIdentifier } from "../../models";
import { ResultStatusErrorType } from "@iqmetrix/antd";

type Action =
  | { type: "load" }
  | { type: "success"; pointyProduct: ProductIdentifier }
  | { type: "error"; statusCode: ResultStatusErrorType };

export type PointyProductStatus = "loading" | "success" | "error";

export type PointyProductState = {
  status: PointyProductStatus;
  pointyProduct: ProductIdentifier;
  errorType?: ResultStatusErrorType;
};

function reducer(state: PointyProductState, action: Action): PointyProductState {
  switch (action.type) {
    case "load":
      return { ...state, status: "loading" };
    case "success":
      return { ...state, status: "success", pointyProduct: action.pointyProduct };
    case "error":
      return { ...state, status: "error", errorType: action.statusCode };
    default:
      return { ...state };
  }
}

const initialState: PointyProductState = { status: "loading", pointyProduct: {} as ProductIdentifier };

export const usePointyProduct = (companyId?: number, storeCode?: string, upc?: string) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!storeCode || !upc) return;

    const fetchPointyProduct = async () => {
      dispatch({ type: "load" });
      try {
        var request: Promise<Response>;
        if (companyId) {
          request = fetch(ProductPageEntryEndpoint(companyId, storeCode, upc));
        } else {
          request = fetch(ProductPageEntryDeprecatedEndpoint(storeCode, upc));
        }
        const result = await request;
        if (!result.ok) throw result;
        const pointyProduct = (await result.json()) as ProductIdentifier;

        dispatch({ type: "success", pointyProduct });
      } catch (error: any) {
        if (error instanceof Response) {
          dispatch({ type: "error", statusCode: error.status.toString() as ResultStatusErrorType });
        } else {
          dispatch({ type: "error", statusCode: "500" });
        }
      }
    };

    fetchPointyProduct();
  }, [companyId, storeCode, upc]);

  return { state };
};
