import { Card, Space } from "@iqmetrix/antd";
import React from "react";
import { useIntl } from "../../hooks";
import { ChangeLocationButton } from "../product-page";
import { LocationDetails } from "../../components";
import { useLocationDetails } from "../location";

export const LocationCard: React.FC = () => {
  const intl = useIntl();
  const { locationDetails } = useLocationDetails();

  return (
    <Card title={intl.formatMessage("Reservation.Pickup")}>
      <Space direction="vertical">
        <LocationDetails location={locationDetails} />
        <ChangeLocationButton />
      </Space>
    </Card>
  );
};
