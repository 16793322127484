import { Card, Typography, Spin, Skeleton, Form, InputNumber } from "@iqmetrix/antd";
import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import * as tokens from "@iqmetrix/style-tokens";
import { ReservationContext } from "../../contexts";
import { useIntl } from "../../hooks";

const { Text, Title } = Typography;
const IMAGE_WIDTH = "71px";

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: ${tokens.SpacingBaseLarge};
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled(Text)`
  flex-grow: 1;
  text-align: right;
`;

const ProductName = styled(Title)`
  && {
    margin-top: 0px;
  }
`;

const PlaceholderImage = styled(Skeleton.Image)`
  .ant-skeleton {
    display: flex;
  }
  && {
    width: ${IMAGE_WIDTH};
  }
`;

const Image = styled.img`
  width: ${IMAGE_WIDTH};
  max-height: 100px;
  object-fit: contain;
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
`;

const Quantity = styled(Form.Item)`
  display: block;
`;

const NewQuantity = styled(InputNumber)`
  width: 57px;
  height: ${tokens.HeightInputBase};
`;

interface ProductCardProps {
  disabled?: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({ disabled }) => {
  const intl = useIntl();
  const { reservationState, dispatch: reservationDispatch } = useContext(ReservationContext);
  const { details: product, quantity } = { ...reservationState.reservation?.product };
  const { reservation } = reservationState;
  const disableQuantityField = product && (Number(product.quantityOnHand) <= 0 || disabled);

  const updateQuantity = useCallback(
    (newQuantity) => {
      if (newQuantity && reservation) {
        reservationDispatch({
          type: "update",
          reservation: {
            ...reservation,
            product: {
              ...reservation.product,
              quantity: newQuantity,
            },
          },
        });
      }
    },
    [reservation, reservationDispatch]
  );

  return (
    <Card title={intl.formatMessage("Reservation.Cart")}>
      {product ? (
        <Container>
          {product.assets.length > 0 ? (
            <Image src={product.assets[0]} key={product.assets[0]} alt={product.name} />
          ) : (
            <PlaceholderImage />
          )}
          <ProductDetails>
            <ProductName level={4}>{product.name}</ProductName>
            <Quantity label={intl.formatMessage("Product.ReserveQuantity")}>
              <NewQuantity
                type="number"
                min={1}
                max={product.quantityOnHand}
                disabled={disableQuantityField}
                defaultValue={quantity}
                onChange={updateQuantity}
              />
            </Quantity>
          </ProductDetails>
          {product.price !== undefined && <Price>{`${intl.formatPrice(product.price)}`}</Price>}
        </Container>
      ) : (
        <StyledSpin />
      )}
    </Card>
  );
};
