export interface Media {
  url: string;
  altText: string;
  type: MediaType;
}

export enum MediaType {
  Image = "Image",
  Video = "Video",
}

export enum Layout {
  FullWidthMediaWithText = "FullWidthMediaWithText",
  FullWidthMediaWithoutText = "FullWidthMediaWithoutText",
  MediaLeftTextRight = "MediaLeftTextRight",
  MediaRightTextLeft = "MediaRightTextLeft",
}

export enum ProductFeatureTextAlignment {
  AlignLeft = 100,
  Centre = 200,
  AlignRight = 300,
}

export enum Alignment {
  Automate = "Automate",
  MediaLeftTextRight = "MediaLeftTextRight",
  MediaRightTextLeft = "MediaRightTextLeft",
}

export enum TextAlignment {
  Left = "Left",
  Center = "Centre",
  Right = "Right",
}

export interface Feature {
  id: number;
  layout: Layout;
  title?: string;
  body?: string;
  media: Media;
  textAlignment?: TextAlignment;
  textColor?: Color;
  showOverlay?: boolean;
  overlayColor?: Color;
  overlayOpacity?: number;
}

export interface Color {
  red: number;
  green: number;
  blue: number;
}
