import { Card, Typography } from "@iqmetrix/antd";
import React from "react";
import { useIntl } from "../../hooks";

export const PaymentCard: React.FC = () => {
  const intl = useIntl();
  return (
    <Card title={intl.formatMessage("Reservation.Payment")}>
      <Typography.Text>{intl.formatMessage("Reservation.Payment.Method")}</Typography.Text>
    </Card>
  );
};
