import { ProductFeaturesEndpoint } from "../../constants/endpoints";
import { useEffect, useReducer } from "react";
import { Feature } from "../../models";
import { ResultStatusErrorType } from "@iqmetrix/antd";

type Action =
  | { type: "load" }
  | { type: "success"; productFeatures: Feature[] }
  | { type: "error"; statusCode: ResultStatusErrorType };

export type ProductFeatureStatus = "empty" | "loading" | "success" | "error";

export type ProductFeatureState = {
  status: ProductFeatureStatus;
  productFeatures: Feature[];
  errorType?: ResultStatusErrorType;
};

function reducer(state: ProductFeatureState, action: Action): ProductFeatureState {
  switch (action.type) {
    case "load":
      return { ...state, status: "loading" };
    case "success":
      return { ...state, status: "success", productFeatures: action.productFeatures };
    case "error":
      return { ...state, status: "error", errorType: action.statusCode };

    default:
      return { ...state };
  }
}

const initialState: ProductFeatureState = {
  status: "empty",
  productFeatures: [] as Feature[],
};

export const useProductFeatures = (companyId?: number, slug?: string) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!companyId || !slug) return;

    const fetchProductFeatures = async () => {
      dispatch({ type: "load" });
      try {
        const detailsFeaturesResult = await fetch(ProductFeaturesEndpoint(companyId, slug));
        if (!detailsFeaturesResult.ok) throw detailsFeaturesResult;

        const featuresData = await detailsFeaturesResult.json();
        const productFeatures = featuresData as Feature[];

        dispatch({ type: "success", productFeatures });
      } catch (error: any) {
        if (error instanceof Response) {
          dispatch({ type: "error", statusCode: error.status.toString() as ResultStatusErrorType });
        } else {
          dispatch({ type: "error", statusCode: "500" });
        }
      }
    };
    fetchProductFeatures();
  }, [companyId, slug]);

  return { state };
};
