import {
  Button,
  Carousel as AntdCarousel,
  Pagination as AntdPagination,
  LeftOutlined,
  RightOutlined,
  Skeleton,
} from "@iqmetrix/antd";
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import * as tokens from "@iqmetrix/style-tokens";
import { CarouselRef } from "antd/lib/carousel";

const MAX_IMAGE_WIDTH = 176;
const MAX_IMAGE_HEIGHT = 194;

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: ${tokens.PaddingBaseLarge} 0px;
  gap: ${tokens.SpacingBaseLarge};
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: ${MAX_IMAGE_WIDTH}px;
  height: ${MAX_IMAGE_HEIGHT}px;
  object-fit: contain;
`;

const Carousel = styled(AntdCarousel)<{
  displayed?: boolean;
}>`
  width: ${MAX_IMAGE_WIDTH}px;
  display: ${(props) => (props.displayed ? "block" : "none !important")};

  .slick-track {
    display: flex;
    align-items: center;
  }
`;

const IconButton = styled(Button)`
  color: ${tokens.ColorGrey600};
  font-size: 20px;
  padding: 10px;
  :focus {
    color: ${tokens.ColorGrey600};
  }
  :hover {
    color: ${tokens.ColorBackgroundButtonPrimaryBase};
  }
  :disabled {
    visibility: hidden;
    cursor: default;
  }
`;

const Pagination = styled(AntdPagination)`
  display: flex;
  justify-content: center;

  .ant-pagination-next,
  .ant-pagination-prev {
    display: none;
  }
`;

const PlaceholderImage = styled(Skeleton.Image)<{
  displayed?: boolean;
}>`
  display: ${(props) => (props.displayed ? "" : "none")};

  .ant-skeleton {
    display: flex;
  }
  && {
    width: ${MAX_IMAGE_WIDTH}px;
    height: ${MAX_IMAGE_HEIGHT}px;
  }
`;

interface ImageCarouselProps {
  assets: string[];
  altText: string;
}

export const ImageCarousel: React.FC<ImageCarouselProps & React.HTMLAttributes<HTMLDivElement>> = ({
  assets,
  altText,
  className,
}) => {
  const [itemIndex, setItemIndex] = useState(0);
  const [firstImageLoaded, setFirstImageLoaded] = useState<boolean>(false);
  const carouselRef = useRef<CarouselRef>(null);
  const imageCount = assets.length;

  const onFirstImageLoaded = useCallback((index: number) => {
    if (index === 0) {
      setFirstImageLoaded(true);
    }
  }, []);

  const onPaginationChange = useCallback((page: number) => {
    carouselRef.current?.goTo(page - 1);
  }, []);

  const onPrev = useCallback(() => {
    carouselRef.current?.prev();
  }, []);

  const onNext = useCallback(() => {
    carouselRef.current?.next();
  }, []);

  return (
    <Container className={className}>
      <CarouselContainer>
        <IconButton type="text" size="large" onClick={onPrev} disabled={itemIndex <= 0}>
          <LeftOutlined />
        </IconButton>
        {imageCount > 0 ? (
          <>
            <PlaceholderImage displayed={!firstImageLoaded} />
            <Carousel
              dots={false}
              ref={carouselRef}
              beforeChange={(_currentSlide: number, nextSlide: number) => {
                setItemIndex(nextSlide);
              }}
              displayed={firstImageLoaded}
            >
              {assets?.map((assetUrl, index) => (
                <Image src={assetUrl} alt={altText} key={assetUrl} onLoad={() => onFirstImageLoaded(index)} />
              ))}
            </Carousel>
          </>
        ) : (
          <PlaceholderImage displayed />
        )}
        <IconButton type="text" size="large" onClick={onNext} disabled={itemIndex >= imageCount - 1}>
          <RightOutlined />
        </IconButton>
      </CarouselContainer>
      {imageCount > 1 && (
        <Pagination
          simple
          total={imageCount}
          pageSize={1}
          onChange={onPaginationChange}
          current={itemIndex + 1}
          disabled={imageCount <= 1}
          size="small"
        />
      )}
    </Container>
  );
};
