import { Card, Row, Space, Typography, Button, Spin } from "@iqmetrix/antd";
import * as tokens from "@iqmetrix/style-tokens";
import styled from "styled-components";
import React, { useContext } from "react";
import { ReservationContext } from "../../contexts";
import { useIntl } from "../../hooks";
import { StyledDivider } from ".";

const { Text, Title } = Typography;

const StyledCard = styled(Card)`
  background-color: ${tokens.ColorGrey50};
  width: 100%;
  margin-top: 10px;
`;

const Summary = styled(Space)`
  width: 100%;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDisclaimerText = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const Price = styled(Title)`
  flex-grow: 1;
  text-align: right;
  margin-top: 0px;
`;

const ReservationButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
`;

interface ReservationSummaryCardProps {
  disabled?: boolean;
  loading?: boolean;
}

export const ReservationSummaryCard: React.FC<ReservationSummaryCardProps> = ({
  disabled = false,
  loading = false,
}) => {
  const intl = useIntl();
  const { reservationState } = useContext(ReservationContext);
  const { details: product, quantity } = { ...reservationState.reservation?.product };

  const totalPrice = Number(product?.price) * Number(quantity);
  const priceDisclaimer = product?.isWirelessDevice
    ? intl.formatMessage("Product.PriceDisclaimer.Device")
    : intl.formatMessage("Product.PriceDisclaimer.NonDevice");

  return (
    <StyledCard title={intl.formatMessage("Reservation.Summary")}>
      {product ? (
        <Summary direction="vertical" size={16}>
          <SummaryRow>
            {Number(quantity) > 1 ? (
              <Text>
                {intl.formatMessage("Reservation.Summary.Subtotals", {
                  count: `${quantity}`,
                })}
              </Text>
            ) : (
              <Text>
                {intl.formatMessage("Reservation.Summary.Subtotal", {
                  count: `${quantity}`,
                })}
              </Text>
            )}
            <Text>{intl.formatPrice(totalPrice)}</Text>
          </SummaryRow>
          <SummaryRow>
            <Text>{intl.formatMessage("Reservation.Summary.Taxes")}</Text>
            <Text>{intl.formatMessage("Reservation.Summary.Disclaimer")}</Text>
          </SummaryRow>
          <SummaryRow>
            <Text>{intl.formatMessage("Reservation.Summary.AdditionalFees")}</Text>
            <Text>{intl.formatMessage("Reservation.Summary.Disclaimer")}</Text>
          </SummaryRow>
          <StyledDivider />
          <SummaryRow>
            <span>
              <Title level={4}>{`${intl.formatMessage("Reservation.Summary.Due")}:`}</Title>
            </span>
            <span>
              <Price level={4}>{`${intl.formatPrice(totalPrice)}*`}</Price>
            </span>
          </SummaryRow>
          <StyledDisclaimerText>{priceDisclaimer}</StyledDisclaimerText>
          <StyledDivider />
          <ReservationButton>
            <Button type="primary" htmlType="submit" disabled={disabled} loading={loading || !product}>
              {intl.formatMessage("Reservation.CompleteReservation")}
            </Button>
          </ReservationButton>
        </Summary>
      ) : (
        <StyledSpin />
      )}
    </StyledCard>
  );
};
