import { RouteComponentProps } from "@gatsbyjs/reach-router";
import React from "react";

export const NotFoundPage: React.FC<RouteComponentProps> = () => {
  return (
    <div>
      <h1>404 - Not Found!</h1>
    </div>
  );
};
