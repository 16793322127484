import { UnitSystem } from "../shared";
const milesConversion = 1609.344;
const kilometersConversion = 1000;

export function GetDistanceInDesiredUnits(distanceInMeters: number, unitSystem: UnitSystem): number {
  if (unitSystem === "imperial") {
    return distanceInMeters / milesConversion;
  }
  return distanceInMeters / kilometersConversion;
}

export function GetMetersFromDistance(distanceInUnits: number, unitSystem: UnitSystem): number {
  if (unitSystem === "imperial") {
    return distanceInUnits * milesConversion;
  }
  return distanceInUnits * kilometersConversion;
}
