import { Card, Space, Tabs, Spin } from "@iqmetrix/antd";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import { ProductDetails, ProductFeatures, ProductSpecifications, useProductDetailsRequest } from ".";
import { useIntl } from "../../hooks";
import { NotFoundPage } from "..";
import { ReservationContext } from "../../contexts";
import { FormatPath, Paths } from "../../constants/paths";
import { useLocationDetails } from "../location";

const Container = styled(Space)`
  width: 100%;
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
`;

const NoDividerTabs = styled(Tabs)`
  .ant-tabs-nav::before {
    border-bottom: none;
  }
`;

interface ProductPageProps extends RouteComponentProps {
  catalogItemId?: string;
}

export const ProductPage: React.FC<ProductPageProps> = ({ catalogItemId, navigate }) => {
  const { locationDetails } = useLocationDetails();
  const { companyId, locationId, ropisEnabled } = { ...locationDetails };
  const { state, fetchProductDetails } = useProductDetailsRequest();

  useEffect(() => {
    if (companyId && locationId && catalogItemId) {
      fetchProductDetails(companyId, locationId, catalogItemId);
    }
  }, [companyId, locationId, catalogItemId, fetchProductDetails]);

  const { status, productDetails } = state;
  const { dispatch: reservationDispatch } = useContext(ReservationContext);

  const intl = useIntl();
  const featuresTabTitle = intl.formatMessage("Product.Tabs.Features");
  const specificationsTabTitle = intl.formatMessage("Product.Tabs.Specifications");

  const hasFeatures = productDetails.hasFeatures;
  const hasSpecifications = !!productDetails.specifications?.length;

  const onReserve = useCallback(
    (quantity: number) => {
      if (!companyId || !locationId) return;
      reservationDispatch({
        type: "update",
        reservation: { companyId, locationId, product: { quantity: quantity, details: productDetails } },
      });
      navigate?.(FormatPath(Paths.Reservation, { companyId, locationId, catalogItemId }));
    },
    [catalogItemId, companyId, locationId, productDetails, navigate, reservationDispatch]
  );

  if (status === "error") {
    return <NotFoundPage />;
  }

  if (status === "loading" || status === "empty") {
    return (
      <>
        <StyledSpin />
      </>
    );
  }

  return (
    <>
      <Container direction="vertical" size={20}>
        <Card>
          <ProductDetails {...productDetails} ropisEnabled={!!ropisEnabled} onReserve={onReserve} />
        </Card>
        {(hasFeatures || hasSpecifications) && (
          <Card>
            <NoDividerTabs>
              {hasFeatures && (
                <Tabs.TabPane tab={featuresTabTitle} key="featuresTab">
                  <ProductFeatures companyId={companyId} slug={productDetails.slug} />
                </Tabs.TabPane>
              )}
              {hasSpecifications && (
                <Tabs.TabPane tab={specificationsTabTitle} key="specificationsTab">
                  <ProductSpecifications specifications={productDetails.specifications} />
                </Tabs.TabPane>
              )}
            </NoDividerTabs>
          </Card>
        )}
      </Container>
    </>
  );
};
