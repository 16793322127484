import React from "react";
import styled from "styled-components";
import { Media, MediaText } from ".";
import { Alignment, MediaType } from "../../models";
import { Col, Row } from "@iqmetrix/antd";

const FullWidthHeight = styled(Row)`
  width: 100%;
  align-items: center;
  flex-direction: ${(props: { reversed: boolean }) => (props.reversed ? "row-reverse" : "row")};
`;

type IMediaCardProps = {
  title: string;
  body: string;
  media: {
    url: string;
    type: MediaType;
    altText: string;
  };
  alignment?: Alignment;
};

export const MediaBesideText: React.FC<IMediaCardProps> = ({
  title,
  body,
  media = {
    url: "",
    type: MediaType.Image,
    altText: "",
  },
  alignment = Alignment.Automate,
}) => {
  return (
    <FullWidthHeight reversed={alignment === Alignment.MediaRightTextLeft}>
      <Col xs={24} md={16}>
        <Media {...media} />
      </Col>
      <Col xs={24} md={8}>
        <MediaText title={title} body={body} />
      </Col>
    </FullWidthHeight>
  );
};
