import React from "react";
import { Media } from ".";
import { MediaType } from "../../models";

type IFullWidthMediaNoTextProps = {
  url: string;
  type: MediaType;
  altText: string;
};

export const FullWidthMediaNoText: React.FC<IFullWidthMediaNoTextProps> = (media) => {
  return <Media {...media} />;
};
