import { Skeleton } from "@iqmetrix/antd";
import React, { useState } from "react";
import styled from "styled-components";
import { MediaType } from "../../models";

type IMediaProps = {
  url: string;
  type: MediaType;
  altText: string;
};

const Placeholder = styled(Skeleton.Image)<{
  displayed: boolean;
}>`
  width: 200px;
  height: 200px;
  display: ${(props) => (props.displayed ? "" : "none")};
`;

const FullWidthImage = styled.img<{
  displayed: boolean;
}>`
  width: 100%;
  display: ${(props) => (props.displayed ? "" : "none")};
`;

const FullWidthVideo = styled.video<{
  displayed: boolean;
}>`
  width: 100%;
  background-color: black;
  display: ${(props) => (props.displayed ? "initial" : "none")};
`;

export const Media: React.FC<IMediaProps> = ({ url, type, altText }) => {
  const [loaded, setLoaded] = useState(false);

  switch (type) {
    case MediaType.Image:
      return (
        <div>
          <Placeholder displayed={!loaded} />
          <FullWidthImage displayed={loaded} src={url} alt={altText} onLoad={() => setLoaded(true)} />
        </div>
      );
    case MediaType.Video:
      return (
        <div>
          <Placeholder displayed={!loaded} />
          <FullWidthVideo
            controls
            data-testid="media-video"
            src={url}
            displayed={loaded}
            onCanPlay={() => setLoaded(true)}
          >
            <track default kind="captions" srcLang="en" />
            <source type="video/mp4" />
            {altText}
          </FullWidthVideo>
        </div>
      );
    default:
      throw new Error("unrecognized media type");
  }
};
