import React, { createContext, useEffect, useState } from "react";

export interface UserState {
  isInternalUser: boolean | null;
}

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserContext = createContext<UserState>({
  isInternalUser: false,
});

export const UserProvider = ({ children }: UserProviderProps) => {
  const [isInternalUser, setIsInternalUser] = useState<boolean | null>(null);

  useEffect(() => {
    async function isInternalUser() {
      try {
        const response = await fetch("/.auth/me");
        const payload = await response.json();
        const { clientPrincipal } = payload;
        const isInternalUser = !!clientPrincipal;
        if (isInternalUser) {
          console.log("Internal user identified.");
        }
        setIsInternalUser(isInternalUser);
      } catch (_) {
        setIsInternalUser(false);
      }
    }
    isInternalUser();
  }, []);

  return <UserContext.Provider value={{ isInternalUser }}>{children}</UserContext.Provider>;
};
