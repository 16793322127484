import React, { useEffect } from "react";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { Spin } from "@iqmetrix/antd";
import styled from "styled-components";
import { Paths, FormatPath } from "../../constants/paths";
import { useSessionStorage } from "../../hooks";
import { usePointyProduct } from ".";

const CenterContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

interface PointyEntryPageProps extends RouteComponentProps {
  companyId?: number;
  storeCode?: string;
  upc?: string;
}

export const PointyEntryPage: React.FC<PointyEntryPageProps> = ({ companyId, storeCode, upc, navigate }) => {
  const { clearCatalogItem } = useSessionStorage();
  const { state } = usePointyProduct(companyId, storeCode, upc);
  const { status, pointyProduct } = state;

  useEffect(() => {
    clearCatalogItem();
  }, [clearCatalogItem]);

  if (status === "success") {
    const productPath = FormatPath(Paths.Product, pointyProduct);
    navigate?.(productPath, { replace: true });
  }

  if (status === "error") {
    navigate?.(Paths.NotFound, { replace: true });
  }

  return (
    <CenterContainer>
      <Spin size="large" />
    </CenterContainer>
  );
};
