import React from "react";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { NotFoundPage } from "..";
import { useLocationDetails } from ".";
import { setTitle } from "@iqmetrix/set-title";

interface LocationRouteProps extends RouteComponentProps {
  companyId?: number;
  locationId?: number;
}

export const Location: React.FC<LocationRouteProps> = ({ companyId, locationId, children }) => {
  const { locationDetails, locationError } = useLocationDetails(companyId, locationId);

  if (locationDetails && !!locationDetails.locationName) {
    setTitle(`${locationDetails?.companyName} - ${locationDetails?.locationName}`);
  }

  if (locationError) {
    return <NotFoundPage />;
  }

  return <>{children}</>;
};
