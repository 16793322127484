import React from "react";
import { Typography } from "@iqmetrix/antd";
import * as tokens from "@iqmetrix/style-tokens";
import styled from "styled-components";
const { Text } = Typography;

export interface ProductOptionsProps {
  options: Record<string, string>;
}

const Option = styled(Text)`
  font-size: ${tokens.FontSizeBaseCaption};
  display: block;
`;

export const ProductOptions: React.FC<ProductOptionsProps> = ({ options }) => {
  return (
    <>
      {Object.entries(options).map(([key, value]) => (
        <Option key={key} strong>{`${key}: ${value}`}</Option>
      ))}
    </>
  );
};
