import { Button, Typography, CheckCircleOutlined, CloseCircleOutlined } from "@iqmetrix/antd";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useIntl } from "../../../hooks";
import { SearchedLocation, Location } from "../../../models";
import * as tokens from "@iqmetrix/style-tokens";
import { LocationDetails } from "../../../components";
const { Text } = Typography;

const Content = styled.div`
  display: flex;
  gap: ${tokens.SpacingBaseXsmall};
  width: 100%;
`;

const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const StyledText = styled(Text)`
  && {
    color: ${(props: { colour?: string }) => props.colour ?? tokens.ColorGrey400};
  }
`;

const CallToReserveText = styled(StyledText)`
  align-self: center;
  text-align: center;
`;

const StockAvailability = styled.div`
  margin-top: ${tokens.MarginBaseXxsmall};
`;

const InStockIcon = styled(CheckCircleOutlined)`
  color: ${tokens.ColorGreen500};
`;

const OutOfStockIcon = styled(CloseCircleOutlined)`
  color: ${tokens.ColorRed500};
`;

const SelectButton = styled(Button)`
  && {
    color: ${tokens.ColorBlue500};
    border-color: ${tokens.ColorBlue500};
  }
`;

interface SearchedLocationProps {
  searchedLocation: SearchedLocation;
  showDistance?: boolean;
  onSelect: (location: Location) => void;
}

export const SearchedLocationDetails: React.FC<SearchedLocationProps> = ({
  searchedLocation,
  showDistance,
  onSelect,
}) => {
  const intl = useIntl();
  const { location, productAvailability, distance } = searchedLocation;

  const onSelectButtonClicked = useCallback(() => {
    onSelect(location);
  }, [location, onSelect]);

  return (
    <Content>
      <LocationInfo>
        <LocationDetails location={location} distance={showDistance ? distance : undefined} />
        <StockAvailability>
          {productAvailability === "InStock" ? (
            <StyledText>
              <InStockIcon /> {intl.formatMessage("Product.InStock")}
            </StyledText>
          ) : productAvailability === "OutOfStock" ? (
            <StyledText>
              <OutOfStockIcon /> {intl.formatMessage("Product.SoldOut")}
            </StyledText>
          ) : (
            <StyledText>{intl.formatMessage("Product.CallForAvailability")}</StyledText>
          )}
        </StockAvailability>
      </LocationInfo>
      {location.ropisEnabled ? (
        <SelectButton ghost disabled={productAvailability !== "InStock"} onClick={onSelectButtonClicked}>
          {intl.formatMessage("Location.Select")}
        </SelectButton>
      ) : (
        <CallToReserveText>{intl.formatMessage("Product.CallToReserve")}</CallToReserveText>
      )}
    </Content>
  );
};
