import React from "react";
import { Layout, Feature, Alignment } from "../../models";
import { FullWidthMediaNoText, FullWidthMediaWithText, MediaBesideText } from ".";

type IProductFeatureProps = {
  productFeature: Feature;
};

export const ProductFeature: React.FC<IProductFeatureProps> = ({ productFeature }) => {
  const title = productFeature.title ?? "";
  const body = productFeature.body ?? "";
  switch (productFeature.layout) {
    case Layout.FullWidthMediaWithText:
      return <FullWidthMediaWithText {...productFeature} title={title} body={body} />;
    case Layout.FullWidthMediaWithoutText:
      return <FullWidthMediaNoText {...productFeature.media} />;
    case Layout.MediaLeftTextRight:
      return <MediaBesideText {...productFeature} title={title} body={body} alignment={Alignment.MediaLeftTextRight} />;
    case Layout.MediaRightTextLeft:
      return <MediaBesideText {...productFeature} title={title} body={body} alignment={Alignment.MediaRightTextLeft} />;
    default:
      throw new Error("unrecognized Layout type");
  }
};
