// The settings are applied in the index.tsx and swapped in the release pipeline
interface Config {
  appName: string;
  environment: "local" | "int" | "rc" | "prod";
  storefrontBffUrl: string;
  supportedLanguages: string[];
  fullstoryOrgId: string;
  recaptchaKey: string;
  carrierFieldNames: string[];
  pageViewTimeout: number;
  reCaptchaActionNameForPageView: string;
  reCaptchaActionNameForCreateReservation: string;
}

const defaultConfig: Config = {
  appName: "Storefront",
  environment: "local",
  // storefrontBffUrl: "https://localhost:44347",
  storefrontBffUrl: "https://storefront-int.azure.development.k8s.iqmetrix.net",
  supportedLanguages: ["en"],
  fullstoryOrgId: "o-1A2CZG-na1",
  recaptchaKey: "6Lf876QgAAAAABNA4W2k1usXTmaC6O0Y6cJUivOT",
  carrierFieldNames: ["Carrier", "Carrier Name"],
  pageViewTimeout: 5000,
  reCaptchaActionNameForPageView: "SendPageView",
  reCaptchaActionNameForCreateReservation: "CreateReservation",
};

export const Configuration: Config = {
  ...defaultConfig,
  ...(window as any).STOREFRONT_CONFIG,
};
