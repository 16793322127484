import { Space, Spin } from "@iqmetrix/antd";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";
import { ProductFeature } from "../../components/product-feature-layout";
import { Feature } from "../../models";
import { useProductFeatures } from ".";

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
`;

type ProductFeaturesProps = {
  companyId?: number;
  slug?: string;
};

export const ProductFeatures: React.FC<ProductFeaturesProps> = ({ companyId, slug }) => {
  const { state } = useProductFeatures(companyId, slug);
  const { status, productFeatures } = state;

  if (status === "loading") {
    return <StyledSpin />;
  }

  return (
    <Space direction="vertical">
      {productFeatures.map((feature: Feature) => {
        return (
          <LazyLoad height={window.innerHeight} key={feature.id}>
            <ProductFeature productFeature={feature} />
          </LazyLoad>
        );
      })}
    </Space>
  );
};
