import { ProductReservationEndpoint } from "../../constants/endpoints";
import { useCallback, useReducer } from "react";
import {
  Reservation,
  ReservationRequest,
  ReservationConfirmedResponse,
  ReservationFailedResponse,
  CustomerDetails,
} from "../../models";
import { ResultStatusErrorType } from "@iqmetrix/antd";

type Action =
  | { type: "empty" }
  | { type: "load" }
  | { type: "success"; reservationConfirmation: ReservationConfirmedResponse }
  | { type: "error"; statusCode: ResultStatusErrorType; response?: ReservationFailedResponse };

export type ReservationRequestStatus = "empty" | "loading" | "success" | "error";

export interface ReservationRequestState {
  status: ReservationRequestStatus;
  confirmation?: ReservationConfirmedResponse;
  errorType?: ResultStatusErrorType;
  failedDetails?: ReservationFailedResponse;
}

function reducer(state: ReservationRequestState, action: Action): ReservationRequestState {
  switch (action.type) {
    case "empty":
      return { ...state, status: "empty" };
    case "load":
      return { ...state, status: "loading" };
    case "success":
      return { ...state, status: "success", confirmation: action.reservationConfirmation };
    case "error":
      return { ...state, status: "error", errorType: action.statusCode, failedDetails: action.response };

    default:
      return { ...state };
  }
}

const initialState: ReservationRequestState = {
  status: "empty",
};

export const useReservationRequest = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const resetErrors = useCallback(() => {
    dispatch({ type: "empty" });
  }, [dispatch]);

  const makeReservation = useCallback(
    async (customerDetails: CustomerDetails, reservation: Reservation, recaptchaToken: string) => {
      dispatch({ type: "load" });
      try {
        const reservationRequest = {
          products: [
            {
              catalogItemId: reservation.product.details?.catalogItemId,
              quantity: reservation.product.quantity,
              price: reservation.product.details?.price,
            },
          ],
          customer: customerDetails,
        } as ReservationRequest;

        const response = await fetch(ProductReservationEndpoint(reservation.companyId, reservation.locationId), {
          method: "POST",
          body: JSON.stringify(reservationRequest),
          headers: { "Content-Type": "application/json", accept: "text/plain", token: recaptchaToken },
        });

        if (!response.ok) throw response;
        const reservationConfirmation = (await response.json()) as ReservationConfirmedResponse;
        dispatch({ type: "success", reservationConfirmation: { ...reservationConfirmation } });
      } catch (error: any) {
        if (error instanceof Response) {
          dispatch({
            type: "error",
            statusCode: error.status.toString() as ResultStatusErrorType,
            response: (await error.json())?.content as ReservationFailedResponse,
          });
        } else {
          dispatch({ type: "error", statusCode: "500" });
        }
      }
    },
    [dispatch]
  );

  return { state, makeReservation, resetErrors };
};
