import { useContext, useEffect, useState } from "react";
import { LocationDetailsEndpoint } from "../../constants/endpoints";
import { Location } from "../../models";
import { LocationContext } from "../../contexts";
import { useSessionStorage } from "../../hooks";

export const useLocationDetails = (companyId?: number, locationId?: number) => {
  const { loadLocation, saveLocation } = useSessionStorage();
  const { locationState, dispatch } = useContext(LocationContext);
  const { locationDetails } = locationState;
  const [locationError, setLocationError] = useState<string>("");

  useEffect(() => {
    if (!companyId || !locationId) return;

    const storedLocation = loadLocation(companyId, locationId);
    if (!!storedLocation) {
      dispatch({ type: "update", locationDetails: storedLocation });
    } else {
      const fetchLocation = async () => {
        setLocationError("");
        try {
          const response = await fetch(LocationDetailsEndpoint(companyId, locationId));
          if (!response.ok) throw response;
          const locationResponse = (await response.json()) as Location;
          saveLocation(locationResponse);
          dispatch({ type: "update", locationDetails: locationResponse });
        } catch (error: any) {
          setLocationError(error);
        }
      };
      fetchLocation();
    }
  }, [companyId, locationId, loadLocation, saveLocation, dispatch]);

  return { locationDetails, locationError };
};
