import React, { ErrorInfo } from "react";
import { Result } from "@iqmetrix/antd";

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          title="There's a problem loading this page"
          subTitle={
            <>
              There’s a technical problem with the app that has prevented this page from loading. Unsaved information
              may be lost. Try reloading this page or going to another page in the app. If that doesn’t work, contact{" "}
              <a href="mailto:support@iqmetrix.com">support@iqmetrix.com</a> for updates and try again later.
            </>
          }
        />
      );
    } else {
      return this.props.children ?? null;
    }
  }
}

export { ErrorBoundary };
