import { Col, Form, Row, Space, Alert, Typography } from "@iqmetrix/antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { Redirect, RouteComponentProps } from "@gatsbyjs/reach-router";
import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  CustomerDetailsCard,
  LocationCard,
  PaymentCard,
  ProductCard,
  ReservationSummaryCard,
  ReservationBreadcrumb,
  useReservationRequest,
} from ".";
import { FormatPath, Paths } from "../../constants/paths";
import { CustomerDetails } from "../../models";
import * as tokens from "@iqmetrix/style-tokens";
import { useIntl } from "../../hooks";
import { useProductDetailsRequest } from "../product-page";
import { ReservationContext } from "../../contexts";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Configuration } from "../../constants/config";

const { Title } = Typography;

const ReservationDetails = styled(Space)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  display: flex;
  justify-content: flex-start;
  margin-top: ${tokens.MarginBaseXsmall};
`;

const StyledAlert = styled(Alert)`
  margin-top: ${tokens.MarginBaseXsmall};
`;

interface ProductReservationPageProps extends RouteComponentProps {
  companyId?: string;
  locationId?: string;
  catalogItemId?: string;
}

export const ProductReservationPage: React.FC<ProductReservationPageProps> = (props) => {
  const companyId = Number(props.companyId);
  const locationId = Number(props.locationId);
  const catalogItemId = String(props.catalogItemId);

  const intl = useIntl();

  const { reservationState, dispatch: reservationDispatch } = useContext(ReservationContext);
  const { reservation } = reservationState;

  const { state: reservationRequestState, makeReservation, resetErrors } = useReservationRequest();
  const { status: reservationRequestStatus, failedDetails: reservationFailedDetails } = reservationRequestState;
  const { reservationCode: reservationConfirmationCode } = { ...reservationRequestState.confirmation };

  const { state: productDetailsRequestState, fetchProductDetails } = useProductDetailsRequest();

  const [form] = Form.useForm<CustomerDetails>();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (reservation && (reservation.companyId !== companyId || reservation.locationId !== locationId)) {
      reservationDispatch({
        type: "update",
        reservation: { ...reservation, companyId, locationId, product: { ...reservation.product, details: undefined } },
      });
      resetErrors();
      fetchProductDetails(companyId, locationId, catalogItemId);
    }
  }, [reservation, companyId, locationId, catalogItemId, reservationDispatch, fetchProductDetails, resetErrors]);

  useEffect(() => {
    if (productDetailsRequestState.status === "success" && productDetailsRequestState.productDetails) {
      reservationDispatch({
        type: "updateProductDetails",
        details: productDetailsRequestState.productDetails,
      });
    }
  }, [productDetailsRequestState, fetchProductDetails, reservationDispatch]);

  useEffect(() => {
    if (reservationRequestStatus === "error" || reservationRequestStatus === "success") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [reservationRequestStatus]);

  const onCompleteReservation = useCallback(
    async (customerDetails: CustomerDetails) => {
      if (reservation && executeRecaptcha) {
        const token = await executeRecaptcha(Configuration.reCaptchaActionNameForCreateReservation);
        if (token) {
          makeReservation(customerDetails, reservation, token);
        }
      }
    },
    [reservation, executeRecaptcha, makeReservation]
  );

  const onValidationError = useCallback(
    (errorInfo: ValidateErrorEntity<CustomerDetails>) => {
      if (errorInfo.errorFields.length > 1) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        form.getFieldInstance(errorInfo.errorFields[0].name).focus();
      }
    },
    [form]
  );

  const onFormLoad = useCallback(() => {
    // prevents validation from being triggered on form load
    form.resetFields();
  }, [form]);

  if (!reservation || productDetailsRequestState.status === "error") {
    return <Redirect to={FormatPath(Paths.Product, { companyId, locationId, catalogItemId })} noThrow />;
  }

  const alertBackLink = (
    <a href={`${FormatPath(Paths.Product, { companyId, locationId, catalogItemId })}`}>
      {intl.formatMessage("Reservation.Alert.ProductPage")}
    </a>
  );

  const formDisabled =
    reservationRequestStatus === "loading" ||
    reservationRequestStatus === "success" ||
    (reservationRequestStatus === "error" &&
      (reservationFailedDetails?.failedProducts[0]?.failureType === "NotEnoughProduct" ||
        reservationFailedDetails?.failedProducts[0]?.failureType === "NoProduct"));

  return (
    <ReservationDetails direction="vertical">
      <ReservationBreadcrumb />
      {(reservationRequestStatus === "success" || reservationRequestStatus === "error") && (
        <StyledAlert
          type={reservationRequestStatus === "success" ? "success" : "error"}
          message={
            <Title
              level={4}
              id={reservationRequestStatus === "success" ? "successful-reservation" : "failed-reservation"}
            >
              {reservationRequestStatus === "success"
                ? intl.formatMessage("Reservation.Alert.Success.Message", {
                    reservationNumber: `${reservationConfirmationCode}`,
                  })
                : reservationFailedDetails?.failedProducts[0]?.failureType === "NoProduct"
                ? intl.formatMessage("Reservation.Alert.NotInStock.Message", {
                    productPageUrl: alertBackLink,
                  })
                : reservationFailedDetails?.failedProducts[0]?.failureType === "NotEnoughProduct"
                ? intl.formatMessage("Reservation.Alert.InsufficientStock.Message", {
                    productPageUrl: alertBackLink,
                  })
                : intl.formatMessage("Reservation.Alert.GenericError.Message")}
            </Title>
          }
          description={
            reservationRequestStatus === "success"
              ? intl.formatMessage("Reservation.Alert.Success.Description", {
                  email: `${form.getFieldValue("email")}`,
                })
              : reservationFailedDetails?.failedProducts[0]?.failureType === "NoProduct"
              ? intl.formatMessage("Reservation.Alert.NotInStock.Description", {
                  productPageUrl: alertBackLink,
                })
              : reservationFailedDetails?.failedProducts[0]?.failureType === "NotEnoughProduct"
              ? intl.formatMessage("Reservation.Alert.InsufficientStock.Description", {
                  productPageUrl: alertBackLink,
                })
              : intl.formatMessage("Reservation.Alert.GenericError.Description")
          }
        />
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={onCompleteReservation}
        onFinishFailed={onValidationError}
        preserve={false}
        validateTrigger="onBlur"
        onLoad={onFormLoad}
      >
        <StyledRow gutter={[20, 20]}>
          <Col xs={24} lg={16}>
            <ReservationDetails direction="vertical" size={20}>
              <ProductCard disabled={formDisabled} />
              <LocationCard />
              <CustomerDetailsCard disabled={formDisabled} />
              <PaymentCard />
            </ReservationDetails>
          </Col>
          <Col xs={24} lg={8}>
            <ReservationSummaryCard disabled={formDisabled} loading={reservationRequestStatus === "loading"} />
          </Col>
        </StyledRow>
      </Form>
    </ReservationDetails>
  );
};
