import React from "react";
import { Typography } from "@iqmetrix/antd";
import * as tokens from "@iqmetrix/style-tokens";
import styled from "styled-components";
import { useIntl } from "../../hooks";
import { Location, Time } from "../../models";
import { GetLocationOpenStatus, GetNextOpenTime, GetTodaysHours } from "../../utilities";
const { Text } = Typography;

const ColoredText = styled(Text)`
  && {
    color: ${(props: { color?: string }) => props.color ?? tokens.ColorGrey600};
  }
`;

interface LocationHoursProps {
  location: Location;
}

export const LocationHours: React.FC<LocationHoursProps> = ({ location }) => {
  const intl = useIntl();
  const openStatus = location ? GetLocationOpenStatus(location) : "Closed";
  var hoursMessage: string | undefined = undefined;
  var hoursColour: string | undefined = undefined;

  function getStoreOpenMessage(closingSoon: boolean, close?: Time): string | undefined {
    if (close) {
      return intl.formatMessage(closingSoon ? "Location.StoreHours.ClosingSoon" : "Location.StoreHours.Open", {
        close: intl.convertTime(close.hour, close.minute),
      });
    }
    return undefined;
  }

  function getStoreClosedMessage(open?: Time, openDay?: string): string | undefined {
    if (open && openDay) {
      return intl.formatMessage("Location.StoreHours.Closed", {
        openTime: intl.convertTime(open.hour, open.minute),
        openDay: openDay,
      });
    }
    return undefined;
  }

  if (openStatus === "Open") {
    hoursMessage = getStoreOpenMessage(false, GetTodaysHours(location.storeHours)?.close);
  } else if (openStatus === "ClosingSoon") {
    hoursMessage = getStoreOpenMessage(true, GetTodaysHours(location.storeHours)?.close);
    hoursColour = tokens.ColorYellow700;
  } else {
    const nextOpenTime = GetNextOpenTime(location.storeHours);
    hoursMessage = getStoreClosedMessage(nextOpenTime?.openTime, nextOpenTime?.openDay);
    hoursColour = tokens.ColorRed500;
  }

  return hoursMessage ? (
    openStatus === "Open" ? (
      <Text>{hoursMessage}</Text>
    ) : (
      <Text>
        <ColoredText color={hoursColour}>
          {hoursMessage.substring(hoursMessage.indexOf("/*") + 2, hoursMessage.indexOf("*/"))}
        </ColoredText>
        {hoursMessage.substring(hoursMessage.indexOf("*/") + 2)}
      </Text>
    )
  ) : (
    <></>
  );
};
