import React, { useCallback, useState } from "react";
import { Button } from "@iqmetrix/antd";
import styled from "styled-components";
import { useIntl } from "../../../hooks";
import { ChangeLocationModal } from "./ChangeLocationModal";
import { useLocationDetails } from "../../location";

const StyledButton = styled(Button)`
  padding: 0px;
`;

const ChangeLocationButton: React.FC = () => {
  const intl = useIntl();
  const { locationDetails } = useLocationDetails();
  const { hasOtherLocations } = { ...locationDetails };
  const [showModal, setShowModal] = useState(false);

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);

  const onDismiss = useCallback(() => {
    setShowModal(false);
  }, []);

  if (!hasOtherLocations) return null;

  return (
    <>
      <StyledButton type="link" onClick={onShow}>
        {intl.formatMessage("Location.ChangeLocation")}
      </StyledButton>
      <ChangeLocationModal visible={showModal} onDismiss={onDismiss} />
    </>
  );
};

export { ChangeLocationButton };
