import { Col, Layout, Row } from "@iqmetrix/antd";
import React from "react";
import styled from "styled-components";
import { PageHeader, PageFooter } from "../components";
import { ResponsiveColumnsHorizontal } from "../shared";
import * as tokens from "@iqmetrix/style-tokens";

const { Content } = Layout;

const FullHeightLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledCol = styled(Col)`
  padding-top: ${tokens.PaddingBaseLarge};
  padding-bottom: ${tokens.PaddingBaseLarge};
`;

export const PageLayout: React.FC = (props) => {
  return (
    <FullHeightLayout>
      <PageHeader />
      <Content>
        <Row justify="center">
          <StyledCol {...ResponsiveColumnsHorizontal}>{props.children}</StyledCol>
        </Row>
      </Content>
      <PageFooter />
    </FullHeightLayout>
  );
};
